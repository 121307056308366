import { defineStore } from 'pinia'
import axios from 'axios'

export const useNewsStore = defineStore('news', {
  state: () => ({
    data: [],
    loading: false,
    error: null,
    selectedRow: null,
    config: {
      diseaseSearchMapping: {
        'obesity': 'obesity',
        'schizophrenia': 'schizophrenia',
        'tgct': 'tgct',
        'dme': 'Diabetes Mellitus Edema',
        'cp': 'Cerebral Palsy',
      },
      defaultSort: {
        column: 'date_time',
        order: 'desc'
      },
      visibleColumns: [
        'date',
        'brief_title',
        'categories',
        'drug_names',
        'organization_names'
      ],
      variableDisplayNames: {
        'date': 'Date',
        'date_time': 'Date',
        'brief_title': 'Brief Title',
        'type': 'Type',
        'condition_names': 'Conditions',
        'drug_names': 'Drugs',
        'organization_names': 'Organizations',
        'sentiment': 'Sentiment',
        'summary': 'Summary',
        'categories': 'Categories',
        'name': 'Name',
        'fda_any': 'FDA approved (any indication)',
        'fda_specific': 'FDA approved (this indication)',
        'mechanisms': 'Mechanisms',
        'comments': 'Comments',
        'event_name': 'Event',
        'phase': 'Phase',
        'diseases': 'Diseases',
        'description': 'Description',
        'amount': 'Amount',
        'valuation': 'Valuation',
        'buyers': 'Buyers / Investors / Licensors',
        'sellers': 'Sellers / Licensees',
        'investors': 'Investors',
        'total': 'Total Amount',
        'deal_type': 'Deal Type',
        'latest_phase': 'Latest Phase (this indication)',
        'latest_completion_date': 'Latest Completion Date (this indication)',
        'sponsor': 'Sponsor',        
        'modalities': 'Modalities',
        'brand_names': 'Brand Names',
        'developers': 'Developers',
        'initial_approval_date': 'Initial Approval Date',
        'latest_news_date': 'Latest News Date',
        'open_label': 'Open Label',
        'randomized': 'Randomized',
        'placebo': 'Placebo',
        'double_blind': 'Double Blind',
        'investor_type': 'Investor Type',
        'is_active': 'Active',
        'is_biotech_investor': 'Biotech Focus'
//        'relevance': 'Disease↔Drug relevance'
      },
      filters: [
        { var: "date", type: "range" },
        { var: "type", type: "multichoice", style: "checkbox" },
        { var: "condition_names", type: "multichoice", style: "select" },
        { var: "drug_names", type: "multichoice", style: "select" },
        { var: "organization_names", type: "multichoice", style: "select" },
        { var: "categories", type: "multichoice", style: "select" },
        { var: "fda_any", type: "multichoice", style: "select" },
        { var: "fda_specific", type: "multichoice", style: "select" },
        { var: "mechanisms", type: "multichoice", style: "select" },
        { var: "phase", type: "multichoice", style: "select" },
        { var: "diseases", type: "multichoice", style: "select" },
        { var: "deal_type", type: "multichoice", style: "select" },
        { var: "modalities", type: "multichoice", style: "select" },
        { var: "developers", type: "multichoice", style: "select" },
        { var: "manufacturers", type: "multichoice", style: "select" },
        { var: "sellers", type: "multichoice", style: "select" },
        { var: "buyers", type: "multichoice", style: "select" },
        { var: "open_label", type: "boolean", style: "checkbox" },
        { var: "randomized", type: "boolean", style: "checkbox" },
        { var: "placebo", type: "boolean", style: "checkbox" },
        { var: "double_blind", type: "boolean", style: "checkbox" },
//        { var: "relevance", type: "multichoice", style: "select" },
      ],
      variableType: {
        "date": "date",
        "type": "string",
        "brief_title": "string",
        "condition_names": "array",
        "drug_names": "array",
        "drug_name": "string",
        "organization_names": "array",
        "sentiment": "string",
        "categories": "array",
        "name": "string",
        "fda_any": "string",
        "mechanisms": "array",
        "comments": "string",
        "event_name": "string",
        "phase": "string",
        "diseases": "array",
        "description": "string",
        "amount": "dollar",
        "valuation": "dollar",
        "buyers": "array",
        "sellers": "array",
        "investors": "array",
        "total": "dollar",
        "revenue": "million_dollars",
        "investor_type": "string",
        "is_active": "boolean",
        "is_biotech_investor": "boolean",
        //        "relevance": "string"
      },
      categoryIcons: {
        'approval': { icon: 'fa-check-circle', color: '#e8f5e9' },
        'clearance': { icon: 'fa-clipboard-check', color: '#e8f5e9' },
        'investment': { icon: 'fa-money-bill-wave', color: '#e8f5e9' },
        'newco': { icon: 'fa-building', color: '#e8f5e9' },
        'grant': { icon: 'fa-gift', color: '#e8f5e9' },
        'partnership': { icon: 'fa-hands-helping', color: '#e3f2fd' },
        'M&A': { icon: 'fa-handshake', color: '#e3f2fd' },
        'results': { icon: 'fa-chart-bar', color: '#e3f2fd' },
        'NDA': { icon: 'fa-file-signature', color: '#e3f2fd' },
        'FDA': { icon: 'fa-file-signature', color: '#e3f2fd' },
        'IND': { icon: 'fa-file-signature', color: '#e3f2fd' },
        'catalyst': { icon: 'fa-bolt', color: '#fff8e1' },
        'breakthrough': { icon: 'fa-bolt', color: '#fff8e1' },
        'fast track': { icon: 'fa-fast-forward', color: '#fff8e1' },
        'CRL': { icon: 'fa-envelope-open-text', color: '#fff8e1' },
        'orphan': { icon: 'fa-star', color: '#fff8e1' },
        'resubmission': { icon: 'fa-redo', color: '#fff8e1' },
        'rejection': { icon: 'fa-times-circle', color: '#ffebee' },
        'hold on IND': { icon: 'fa-hand-paper', color: '#ffebee' },
        'lawsuit': { icon: 'fa-gavel', color: '#ffebee' },
        'patent': { icon: 'fa-file-signature', color: '#ffebee' },
        'layoffs': { icon: 'fa-user-minus', color: '#ffebee' },
        'China': { icon: 'fa-flag', color: '#f3e5f5' },
        'Canada': { icon: 'fa-flag', color: '#f3e5f5' },
        'Japan': { icon: 'fa-flag', color: '#f3e5f5' },
        'Australia': { icon: 'fa-flag', color: '#f3e5f5' },
        'EU': { icon: 'fa-euro-sign', color: '#f3e5f5' },
        'US': { icon: 'fa-flag-usa', color: '#f3e5f5' },
        'UK': { icon: 'fa-flag-uk', color: '#f3e5f5' },
        'Asia': { icon: 'fa-globe-asia-australia', color: '#f3e5f5' },
        'conference': { icon: 'fa-users', color: '#f5f5f5' },
        'webinar': { icon: 'fa-video', color: '#f5f5f5' },
        'financial statement': { icon: 'fa-file-invoice-dollar', color: '#f5f5f5' },
        'reimbursement': { icon: 'fa-file-invoice-dollar', color: '#f5f5f5' },
        'management': { icon: 'fa-user-tie', color: '#f5f5f5' },
        'type A meeting': { icon: 'fa-comments', color: '#f5f5f5' },
        'manufacturing': { icon: 'fa-industry', color: '#f5f5f5' },
        'process': { icon: 'fa-cogs', color: '#f5f5f5' },
        'market analysis': { icon: 'fa-chart-line', color: '#f5f5f5' },
        'other': { icon: 'fa-circle', color: '#f5f5f5' },
        'regulatory': { icon: 'fa-clipboard-check', color: '#e8f5e9' },
        "India": { icon: 'fa-flag', color: '#f3e5f5' },
        'publication': { icon: 'fa-file-alt', color: '#f3e5f5' },
        'South Korea': { icon: 'fa-flag', color: '#f3e5f5' },
        "Taiwan": { icon: 'fa-flag', color: '#f3e5f5' },
        "biosimilar": { icon: 'fa-file-signature', color: '#e3f2fd' },
        'pipeline': { icon: 'fa-file-signature', color: '#e3f2fd' },
        "priority review": { icon: 'fa-file-signature', color: '#e3f2fd' },
      },
      dealTypeIcons: {
        'm&a': { icon: 'fa-handshake', color: '#e3f2fd' },
        'ipo': { icon: 'fa-handshake', color: '#e3f2fd' },
        'investment': { icon: 'fa-money-bill-wave', color: '#e8f5e9' },
        'licensing': { icon: 'fa-file-contract', color: '#fff8e1' },
        'partnership': { icon: 'fa-hands-helping', color: '#e3f2fd' },
        'joint venture': { icon: 'fa-people-arrows', color: '#e3f2fd' },
        'asset purchase': { icon: 'fa-shopping-cart', color: '#e8f5e9' },
        'default': { icon: 'fa-circle', color: '#f5f5f5' },
        'loan': { icon: 'fa-handshake', color: '#e3f2fd' },
        'grant': { icon: 'fa-gift', color: '#e3f2fd' },
        'registered direct offering': { icon: 'fa-handshake', color: '#e3f2fd' },
        'public offering': { icon: 'fa-handshake', color: '#e3f2fd' },
        'private placement': { icon: 'fa-handshake', color: '#e3f2fd' },
      }
    },
    filterValues: {},
    selectedRanges: {},
    filterRanges: {},
    visibleColumns: [],
    loadedDiseases: new Set(),
    diseaseData: new Map(),
    viewTypeConfig: {
      news: {
        endpoint: 'news',
        visibleColumns: [
          'date',
          'brief_title',
          'categories',
          'drug_names',
          'organization_names'
        ],
        presetFilters: {},
        detailsComponent: 'NewsDetailsComponent',
        preprocessData: (entry) => ({
          ...entry,
          brief_title: entry.annotations_auto?.brief_title || '',
          type: entry.annotations_auto?.type || 'Unknown',
          drug_names: entry.annotations_auto?.drug_names || [],
          categories: entry.annotations_auto?.categories || [],
          organization_names: entry.annotations_auto?.organization_names || [],
          date: entry.date_time ? entry.date_time.split(' ')[0] : null,
          summary: entry.annotations_auto?.summary?.join('\n') || '',
        })
      },
/*      catalyst: {
        endpoint: 'catalysts',
        visibleColumns: [
          'date_from',
          'date_to',
          'date_desc',
          'event_name',
          'type',
          'phase',
          'drug_names',
          'organization_names',
          'diseases'
        ],
        presetFilters: {},
        detailsComponent: 'CatalystDetailsComponent',
        preprocessData: (entry) => ({
          ...entry,
          brief_title: entry.event_name,
          date_from: entry.dates.from,
          date_to: entry.dates.to,
          date_desc: entry.dates.desc,
          date: entry.dates.from.split(' ')[0],
          organization_names: [entry.organization.name],
          condition_names: entry.diseases,
          categories: ['catalyst'],
          description: entry.description
        })
      },*/
      deals: {
        endpoint: 'deals',
        visibleColumns: [
          'date',
          'brief_title',
          'deal_type',
          'total',
          'buyers',
          'sellers',
        ],
        presetFilters: {},
        detailsComponent: 'DealDetailsComponent',
        preprocessData: (entry) => ({
          ...entry,
          date_time: entry.date,
          organization_names: [...new Set([...entry.buyers, ...entry.sellers])],
          description: entry.sources?.[0]?.title,
          deal_type: entry.types,
          source: entry.sources?.[0],
          total: Number(entry.total),
          milestones: Number(entry.milestones),
          sources: entry.sources?.map(source => ({
            ...source,
            summary: source.summary || []
          })) || [],
          summary: entry.summary || []
        })
      },
      drugs: {
        endpoint: 'drugs',
        filters: [
          { var: "name", type: "multichoice", style: "select" },
        ],
        visibleColumns: [
          'name',
          'revenue',
          'latest_phase',
          'latest_news_date',
          'latest_completion_date',
          'developers',
          'mechanisms',
          'modalities',
          'fda_specific',
          'fda_any',
          'initial_approval_date',
          'relevance'
        ],
        variableDisplayNames: {
          'name': 'Drug Name',
          'latest_phase': 'Latest Phase',
          'latest_news_date': 'Latest News',
          'latest_completion_date': 'Latest Trial Completion',
        },
        presetFilters: {
//          relevance: ['Directly relevant', 'Indirectly relevant']
        },
        detailsComponent: 'DrugDetailsComponent',
        defaultSort: {
          column: 'latest_phase',
          order: 'desc'
        },
        preprocessData: (entry) => ({
          ...entry,
          brief_title: entry.name,
          drug_names: [entry.name, ...(entry.other_names || [])],
          organization_names: entry.sponsors || [],
          categories: entry.mechanisms || [],
          type: entry.fda_specific || 'Unknown',
          date: entry.latest_news_date
        })
      },
      trials: {
        endpoint: 'trials',
        visibleColumns: [
          'drug_names',
          'phase',
          'sponsor',
          'completion_date',
          'eligibility',
          'open_label',
          'randomized',
          'placebo',
          'double_blind'
        ],
        presetFilters: {},
        detailsComponent: 'TrialDetailsComponent',
        preprocessData: (entry) => ({
          ...entry,
          brief_title: entry.annotations_auto?.brief_title,
          drug_names: [entry.annotations_auto?.drug_name, ...(entry.annotations_auto.other_drugs || [])],
          phase: entry.phase ? entry.phase.substring(5, 6) : entry.phase,
          sponsor: entry.sponsor,
          completion_date: entry.completion_date,
          eligibility: entry.annotations_auto?.eligibility?.comment || 'N/A',
          diseases: entry.diseases || [],
          open_label: entry.annotations_auto?.openlabel || false,
          randomized: entry.annotations_auto?.randomized || false,
          placebo: entry.annotations_auto?.placebo || false,
          double_blind: entry.annotations_auto?.doubleblind || false
        })
      },
      funds: {
        endpoint: 'funds',
        visibleColumns: [
          'name',
          'investor_type',
          'is_active',
          'is_biotech_investor',
          'summary'
        ],
        filters: [
          { var: "investor_type", type: "multichoice", style: "select" },
          { var: "is_active", type: "boolean", style: "checkbox" },
          { var: "is_biotech_investor", type: "boolean", style: "checkbox" },
        ],
        presetFilters: {},
        detailsComponent: 'FundDetailsComponent',
        preprocessData: (entry) => ({
          ...entry,
          brief_title: entry.name,
          date: entry.validated_at,
          categories: [entry.investor_type],
          organization_names: [entry.name]
        })
      },
      investors: {
        endpoint: 'investors',
        visibleColumns: [
          'name',
          'role',
          'company',
          'city',
          'country',
          'summary'
        ],
        filters: [
          { var: "country", type: "multichoice", style: "select" },
          { var: "role", type: "multichoice", style: "select" },
          { var: "company", type: "multichoice", style: "select" }
        ],
        presetFilters: {},
        detailsComponent: 'FundDetailsComponent',
        preprocessData: (entry) => ({
          ...entry,
          brief_title: `${entry.first_name} ${entry.last_name}`,
          name: `${entry.first_name} ${entry.last_name}`,
          date: entry.start_date,
          categories: [entry.role],
          organization_names: [entry.company].filter(Boolean),
          bullet_points: entry.bullet_points || []
        })
      }
    },
    currentViewType: 'news'
  }),

  actions: {
    setSelectedRow(row) {
      this.selectedRow = row
    },

    getDisplayName(variable) {
      // Check for view-specific display name first
      const viewConfig = this.viewTypeConfig[this.currentViewType];
      if (viewConfig?.variableDisplayNames?.[variable]) {
        return viewConfig.variableDisplayNames[variable];
      }
      // Check global display names
      if (this.config.variableDisplayNames[variable]) {
        return this.config.variableDisplayNames[variable];
      }
      // If no display name found, capitalize the variable name
      return variable.charAt(0).toUpperCase() + variable.slice(1).replace(/_/g, ' ');
    },

    getVariableType(variable) {
      return this.config.variableType[variable] || 'string';
    },

    updateFilterValues(filterVar, values) {
      this.filterValues[filterVar] = values;
    },

    updateDateRange(variable, range) {
      this.selectedRanges[variable] = range;
    },

    async fetchNews(doid) {
      this.loading = true
      this.error = null

      try {
        const viewConfig = this.viewTypeConfig[this.currentViewType]
        const endpoint = viewConfig.endpoint || 'news'

        // If we have already fetched data for this doid/endpoint, reuse it
        if (!this.diseaseData.has(doid)) {
          this.diseaseData.set(doid, {})
        }
        const diseaseEndpoints = this.diseaseData.get(doid)

        if (diseaseEndpoints[endpoint]) {
          this.data = diseaseEndpoints[endpoint]
          return
        }

        // Otherwise, fetch from the API
        const response = await axios.get(`https://api.gosset.ai/${endpoint}/?disease=${doid}`)
        
        const processedData = response.data.map(entry => {
          const preprocessed = viewConfig.preprocessData
            ? viewConfig.preprocessData(entry)
            : entry
          return {
            ...preprocessed,
            condition_names: entry.annotations_auto?.condition_names || [],
            sentiment: entry.annotations_auto?.sentiment || null,
          }
        })

        // Store data for this disease and endpoint
        diseaseEndpoints[endpoint] = processedData
        this.data = processedData
        this.loadedDiseases.add(doid)
      } catch (error) {
        this.error = error.message
        console.error('Error fetching news:', error)
      } finally {
        this.loading = false
      }
    },

    clearLoadedDiseases() {
      this.loadedDiseases.clear();
      this.diseaseData.clear();
      this.data = [];
    },

    setViewType(viewType) {
      // Clear selected row when switching views
      this.selectedRow = null;
      
      this.currentViewType = viewType;
      const viewConfig = this.viewTypeConfig[viewType];
      if (viewConfig) {
        this.visibleColumns = [...viewConfig.visibleColumns];
        
        // Reset filters
        if (viewConfig.presetFilters) {
          this.filterValues = {};
          Object.entries(viewConfig.presetFilters).forEach(([key, value]) => {
            this.filterValues[key] = Array.isArray(value) ? [...value] : value;
          });
        }

        // Fetch data for all loaded diseases with the new endpoint
        if (this.loadedDiseases.size > 0) {
          for (const doid of this.loadedDiseases) {
            this.fetchNews(doid);
          }
        }
      }
    }
  },

  getters: {
    isLoading: (state) => state.loading,
    filteredData: (state) => {
      return state.data.filter(item => {
        // First apply preset filters based on view type
        const currentView = state.currentViewType || 'news';
        const presetFilters = state.viewTypeConfig[currentView]?.presetFilters || {};
        const visibleColumns = state.viewTypeConfig[currentView]?.visibleColumns || state.config.visibleColumns;
        
        // Get both default and view-specific filters
        const viewSpecificFilters = state.viewTypeConfig[currentView]?.filters || [];
        const allFilters = [...viewSpecificFilters, ...state.config.filters];
        
        // Apply preset filters only if user hasn't set their own filters
        for (const [filterVar, filterValues] of Object.entries(presetFilters)) {
          // Skip preset filter if user has set their own filter values
          if (state.filterValues[filterVar]?.length === 0) continue;
          if (Object.prototype.hasOwnProperty.call(state.filterValues, filterVar)) continue;
          
          const itemValue = item[filterVar];
          if (Array.isArray(itemValue)) {
            if (!itemValue.some(val => filterValues.includes(val))) {
              return false;
            }
          } else if (!filterValues.includes(itemValue)) {
            return false;
          }
        }

        // Then apply user-selected filters, but only for visible columns
        return allFilters
          .filter(filter => visibleColumns.includes(filter.var))
          .every(filter => {
            const itemValue = item[filter.var];

            // Handle boolean filters
            if (filter.type === 'boolean') {
              const selectedValues = state.filterValues[filter.var] || [];
              
              // If no checkboxes are selected, show all data points
              if (selectedValues.length === 0) return true;

              // Convert boolean value to string for comparison
              const stringValue = String(itemValue);
              return selectedValues.includes(stringValue);
            }

            if (filter.type === 'multichoice') {
              const selectedValues = state.filterValues[filter.var] || [];
              if (selectedValues.length === 0) return true;
              
              // Handle null/undefined values
              if (itemValue === null || itemValue === undefined || itemValue === '') {
                return selectedValues.includes('NA');
              }
              
              // Handle array values
              if (Array.isArray(itemValue)) {
                return itemValue.some(val => selectedValues.includes(val));
              }
              
              // Handle single values
              return selectedValues.includes(itemValue);
            }
            
            if (filter.type === 'range' && state.config.variableType[filter.var] === 'date') {
              const range = state.selectedRanges[filter.var];
              if (!range || (!range.min && !range.max)) return true;
              
              const dateValue = new Date(itemValue);
              if (range.min && dateValue < range.min) return false;
              if (range.max && dateValue > range.max) return false;
              return true;
            }
            
            return true;
          });
      });
    },
    getError: (state) => state.error,
    getVisibleColumns: (state) => {
      const currentView = state.currentViewType || 'news';
      return state.viewTypeConfig[currentView]?.visibleColumns || state.config.visibleColumns;
    },
    getFilters: (state) => {
      const currentView = state.currentViewType || 'news';
      const visibleColumns = state.viewTypeConfig[currentView]?.visibleColumns || state.config.visibleColumns;
      const viewSpecificFilters = state.viewTypeConfig[currentView]?.filters || [];
      
      // Combine view-specific filters with default filters
      const allFilters = [...viewSpecificFilters, ...state.config.filters];
      
      // Only return filters that are relevant to the current view's visible columns
      return allFilters.filter(filter => 
        visibleColumns.includes(filter.var)
      );
    },
    getSortConfig: (state) => {
      const currentView = state.currentViewType || 'news';
      const viewConfig = state.viewTypeConfig[currentView];
      return {
        column: viewConfig?.defaultSort?.column || state.config.defaultSort.column,
        order: viewConfig?.defaultSort?.order || state.config.defaultSort.order
      };
    }
  }
})
