<template>
  <div class="phase-progress-container">
    <div class="phase-progress-bar" v-if="showBar">
      <div class="progress-fill" :style="{ width: progressWidth, backgroundColor: progressColor }"></div>
    </div>
    <span class="phase-number">{{ phaseNumber }}</span>
  </div>
</template>

<script>
export default {
  name: 'PhaseProgressBar',
  props: {
    phase: {
      type: [String, Number],
      required: true,
      validator: (value) => {
        const num = typeof value === 'string' ? parseInt(value.replace('Phase ', '')) : value;
        return num >= 0 && num <= 4;
      }
    }
  },
  computed: {
    showBar() {
      return this.phase !== 'Unknown';
    },
    phaseNumber() {
      if (this.phase === 0 || this.phase === '0') return 'N/A';
      return typeof this.phase === 'string' ? 
        (this.phase === "Preclinical" ? "Preclinical" : this.phase) : 
        `Phase ${this.phase}`;
    },
    progressColor() {
      if (this.phase === 'Discontinued') return '#ff6b6b';
      if (this.phase === 'Terminated') return 'repeating-linear-gradient(45deg, #808080 0px, #808080 10px, #a3a3a3 10px, #a3a3a3 20px)';
      if (this.phase === 'Preclinical') return '#ffd54f';
      if (this.phase === 'Approved') return '#90EE90';
      if (this.phase === 'NDA') return '#349890';
      return '#3498db';
    },
    progressWidth() {
      if (this.phase === 'Approved' || this.phase === 'Discontinued' || this.phase === 'Terminated') return '100%';
      if (this.phase === 'NDA') return '90%';
      if (this.phase === 'Preclinical') return '15%';
      const num = typeof this.phase === 'string' ? 
        parseInt(this.phase.replace('Phase ', '')) : 
        this.phase;
      return `${(num / 4) * 100}%`;
    }
  }
}
</script>

<style scoped>
.phase-progress-container {
  display: flex;
  align-items: center;
  gap: 8px;

}

.phase-progress-bar {
  position: relative;
  width: 60px;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.progress-fill {
  position: absolute;
  height: 100%;
  transition: width 0.3s ease;
  background-color: #3498db; /* fallback */
  background: v-bind('progressColor');
}

.phase-number {
  font-size: 0.8rem;
  color: #666;
}
</style> 