import { defineStore } from 'pinia';
import axios from 'axios';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    firstName: null,
    lastName: null,
    email: null,
    photoUrl: null,
    loading: false,
    error: null
  }),

  actions: {
    async login(email) {
      this.loading = true;
      this.error = null;

      try {
        const response = await axios.post('https://api.gosset.ai/login/', { email });
        const userData = response.data;
        
        // Only set auth data if we got a valid response without errors
        if (userData && !userData.error) {
          this.email = userData.email;
          this.photoUrl = userData.avatar || this.photoUrl;
          this.firstName = userData.first_name || 'Anonymous';
          this.lastName = userData.last_name || 'User';
          return true;
        }
        
        this.error = 'Invalid login credentials';
        return false;
      } catch (error) {
        this.error = 'Login failed. Please try again.';
        console.error('Login error:', error);
        return false;
      } finally {
        this.loading = false;
      }
    },

    logout() {
      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.error = null;
      // Don't reset photoUrl as it's a default value
    }
  },

  getters: {
    isAuthenticated: (state) => !!state.email,
    getFirstName: (state) => state.firstName || 'Guest',
    getLastName: (state) => state.lastName || '',
    getPhotoUrl: (state) => state.photoUrl,
    getError: (state) => state.error,
    isLoading: (state) => state.loading
  }
});