<template>
  <div class="details-component">
    <div>
      <h2>{{ selectedRow.name }}</h2>

      <div class="details-grid">
        <div class="side-by-side">
          <div class="info-item" v-if="selectedRow.developers?.length">
            <label>Developers</label>
            <span>
              <span v-for="developer in selectedRow.developers" 
                    :key="developer" 
                    class="tag-item clickable"
                    :class="{ active: filterValues.developers?.includes(developer) }"
                    @click="addToFilter('developers', developer)">
                {{ developer }}
              </span>
            </span>
          </div>

          <div class="info-item" v-if="selectedRow.manufacturers?.length">
            <label>Manufacturers</label>
            <span>
              <span v-for="manufacturer in selectedRow.manufacturers" 
                    :key="manufacturer" 
                    class="tag-item clickable"
                    :class="{ active: filterValues.manufacturers?.includes(manufacturer) }"
                    @click="addToFilter('manufacturers', manufacturer)">
                {{ manufacturer }}
              </span>
            </span>
          </div>
        </div>

        <div class="info-item" v-if="selectedRow.mechanisms?.length">
          <label>Mechanisms</label>
          <span>
            <span v-for="mechanism in selectedRow.mechanisms" 
                  :key="mechanism" 
                  class="tag-item clickable"
                  :class="{ active: filterValues.mechanisms?.includes(mechanism) }"
                  @click="addToFilter('mechanisms', mechanism)">
              {{ mechanism }}
            </span>
          </span>
        </div>

        <div class="info-item" v-if="selectedRow.modalities?.length">
          <label>Modalities</label>
          <span>
            <span v-for="modality in selectedRow.modalities" 
                  :key="modality" 
                  class="tag-item clickable"
                  :class="{ active: filterValues.modalities?.includes(modality) }"
                  @click="addToFilter('modalities', modality)">
              <i :class="`modality-icon icon-${getModalityIcon(modality)}`"></i>
              {{ modality }}
            </span>
          </span>
        </div>

        <div class="info-item" v-if="selectedRow.trials?.length">
          <label>{{ getDiseaseName }} Clinical Trials</label>
          <!--div class="trials-header">
            <label class="checkbox-label">
              <input 
                type="checkbox" 
                v-model="showOnlyIndustryTrials"
              >
              Show only {{ getDiseaseName }} trials with industry sponsors/collaborators
            </label>
          </div-->
          <table class="data-table">
            <thead>
              <tr>
                <th>NCT ID</th>
                <th>Phase</th>
                <th>Completion</th>
                <th>Primary Diagnosis</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="trial in displayedTrials" :key="trial.nct_id">
                <td>
                  <a :href="`https://clinicaltrials.gov/study/${trial.nct_id}`" 
                     target="_blank" 
                     rel="noopener noreferrer"
                     class="nct-link">
                    {{ trial.nct_id }}
                  </a>
                </td>
                <td>
                  <PhaseProgressBar v-if="trial.phase" :phase="trial.phase" />
                  <span v-else></span>
                </td>
                <td>{{ trial.completion_date || 'N/A' }}</td>
                <td>{{ trial.eligibility?.primary_diagnosis || 'N/A' }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="sortedTrials.length > 10" class="show-more">
            <button @click="toggleShowMore" class="btn btn-outline-primary btn-sm">
              {{ showAllTrials ? 'Show Less' : `Show More (${sortedTrials.length - 10} more)` }}
            </button>
          </div>
        </div>

        <div class="info-item" v-if="selectedRow.development_status && Object.keys(selectedRow.development_status).length">
          <label>Development Status</label>
          <table class="data-table">
            <thead>
              <tr>
                <th>Indication</th>
                <th>Phase</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(phase, indication) in selectedRow.development_status" :key="indication">
                <td>{{ indication }}</td>
                <td>
                  <PhaseProgressBar v-if="phase" :phase="phase" />
                  <span v-else></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="info-item" v-if="selectedRow.news_articles?.length">
          <label>News Articles</label>
          <NewsComponent :articles="selectedRow.news_articles" />
        </div>

        <div class="info-item" v-if="selectedRow.description">
          <label>Description</label>
          <div class="description" v-html="markdownDescription"></div>
        </div>

        <div class="info-item" v-if="selectedRow.brand_names?.length">
          <label>Brand Names</label>
          <span>{{ selectedRow.brand_names.join(', ') }}</span>
        </div>

        <div class="info-item" v-if="selectedRow.code_names?.length">
          <label>Code Names</label>
          <span>{{ selectedRow.code_names.join(', ') }}</span>
        </div>

        <div class="info-item" v-if="selectedRow.other_names?.length">
          <label>Alternative Names</label>
          <span>{{ selectedRow.other_names.join(', ') }}</span>
        </div>

        <div style="height: 1rem;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked';
import PhaseProgressBar from './PhaseProgressBar.vue';
import { useDataStore } from '../stores/dataStore';
import { useNewsStore } from '../stores/newsStore';
import { storeToRefs } from 'pinia'
import NewsComponent from './NewsComponent.vue'
import '@/assets/styles/details-component.css'

export default {
  name: 'DrugDetailsComponent',
  
  components: {
    NewsComponent,
    PhaseProgressBar
  },

  data() {
    return {
      showAllTrials: true,
      showOnlyIndustryTrials: false,
      modalityIcons: {
        'Synthetic glucocorticoid corticosteroid': 'steroid',
        'Monoclonal Antibody': 'monoclonal',
        'Antibody': 'antibody',
        'Antibody Drug Conjugate': 'adc',
        'Antisense': 'antisense',
        'Aptamer': 'aptamer',
        'Peptide': 'peptide',
        'Bispecific Antibody': 'bispecific',
        'Device': 'device',
        'Protein': 'protein',
        'Gene Therapy': 'gene',
        'Antibody Fragment': 'antibody-fragment',
        'Non-Pharmaceutical': 'non-pharma',
        'NSAID': 'nsaid',
        'Fusion Protein': 'fusion-protein',
        'siRNA': 'sirna',
        'Small Molecule': 'small-molecule'
      }
    }
  },

  props: {
    selectedRow: {
      type: Object,
      required: true
    }
  },

  computed: {
    markdownDescription() {
      return this.selectedRow.description ? marked(this.selectedRow.description) : '';
    },
    
    sortedTrials() {
      if (!this.selectedRow.trials) return [];
      let trials = [...this.selectedRow.trials];
      
      // Filter for industry collaborators if checkbox is checked
      if (this.showOnlyIndustryTrials) {
        trials = trials.filter(trial => trial.industry_collaborators);
      }
      
      return trials.sort((a, b) => {
        if (!a.completion_date) return 1;
        if (!b.completion_date) return -1;
        return new Date(b.completion_date) - new Date(a.completion_date);
      });
    },

    getDiseaseName() {
      const dataStore = useDataStore();
      const newsStore = useNewsStore();
      
      // Try to get disease name from dataStore first
      if (dataStore.getDiseaseName) {
        return dataStore.getDiseaseName;
      }
      
      // Fallback to newsStore if needed
      const diseaseConfig = newsStore.viewTypeConfig?.drugs?.diseaseConfig;
      return diseaseConfig?.name || 'Disease';
    },

    displayedTrials() {
      return this.showAllTrials ? this.sortedTrials : this.sortedTrials.slice(0, 10);
    }
  },

  setup() {
    const newsStore = useNewsStore()
    const { filterValues } = storeToRefs(newsStore)
    return { filterValues }
  },

  methods: {
    getModalityIcon(modality) {
      return this.modalityIcons[modality] || 'generic';
    },

    toggleShowMore() {
      this.showAllTrials = !this.showAllTrials;
    },

    addToFilter(filterName, value) {
      const newsStore = useNewsStore()
      const currentValues = this.filterValues[filterName] || []
      
      // If value exists, remove it; if it doesn't exist, add it
      if (currentValues.includes(value)) {
        newsStore.updateFilterValues(filterName, currentValues.filter(v => v !== value))
      } else {
        newsStore.updateFilterValues(filterName, [...currentValues, value])
      }
    }
  }
}
</script>

<style scoped>
h2 {
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
}

.data-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 0.5rem;
}

.nct-link {
  color: #3498db;
  text-decoration: none;
}

.nct-link:hover {
  text-decoration: underline;
}

/* ... keep other specific styles like modality icons, table styles, etc ... */
</style> 