<template>
  <div class="news-component">
    <ViewHeader :title="headerTitle">
      <!-- <template #buttons>
        <button class="action-btn" @click="newsStore.setSelectedRow(null)" v-if="newsStore.selectedRow">
          <i class="fas fa-times"></i>
          Close Details
        </button>
      </template> -->
    </ViewHeader>

    <div class="content">
      <NavigationSidebar 
        currentView="news"
        :currentDisease="disease"
      />
      <div class="filter-panel">
        <FilterControls :store="newsStore" />
      </div>
      
      <div class="table-panel">
        <DataTable 
          :store="newsStore"
          @row-click="handleRowClick"
        />
      </div>

      <div class="resize-handle" 
           v-if="newsStore.selectedRow"
           :style="{ right: detailsPanelWidth + 'px' }" 
           @mousedown="startResize"></div>
      <div class="details-panel" 
           v-if="newsStore.selectedRow"
           :style="{ width: detailsPanelWidth + 'px' }">
        <button class="close-button" @click="newsStore.setSelectedRow(null)">×</button>
        <component :is="detailsComponent"
                   :selectedRow="newsStore.selectedRow"
                   @close="newsStore.setSelectedRow(null)" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { useNewsStore } from '../stores/newsStore'
import { useDataStore } from '../stores/dataStore'
import DataTable from './DataTable.vue'
import NewsDetailsComponent from './NewsDetailsComponent.vue'
import ViewHeader from './ViewHeader.vue'
import FilterControls from './FilterControls.vue'
import NavigationSidebar from './NavigationSidebar.vue'
import DrugDetailsComponent from './DrugDetailsComponent.vue'
import CatalystDetailsComponent from './CatalystDetailsComponent.vue'
import DealDetailsComponent from './DealDetailsComponent.vue'
import TrialDetailsComponent from './TrialDetailsComponent.vue'
import FundDetailsComponent from './FundDetailsComponent.vue'

export default {
  name: 'NewsComponent',
  
  components: {
    DataTable,
    NewsDetailsComponent,
    ViewHeader,
    FilterControls,
    NavigationSidebar,
    DrugDetailsComponent,
    CatalystDetailsComponent,
    DealDetailsComponent,
    TrialDetailsComponent,
    FundDetailsComponent
  },

  props: {
    disease: {
      type: String,
      required: false,
      default: null,
    },
    viewType: {
      type: String,
      default: 'news'
    }
  },

  setup(props) {
    const newsStore = useNewsStore()
    const dataStore = useDataStore()
    const detailsPanelWidth = ref(800)

    watch(
      () => props.viewType,
      (newViewType) => {
        newsStore.setViewType(newViewType)
      }
    )

    const headerTitle = computed(() => {
      return dataStore.getDiseaseConfig?.frontend?.name || 'News'
    })

    const handleRowClick = (row) => {
      console.log('Row clicked:', row);
      newsStore.setSelectedRow({ ...row });
    }

    const startResize = (e) => {
      e.preventDefault();
      const startX = e.clientX;
      const startWidth = detailsPanelWidth.value;

      const resize = (moveEvent) => {
        const diff = startX - moveEvent.clientX;
        detailsPanelWidth.value = Math.min(Math.max(300, startWidth + diff), 1400);
      };

      const stopResize = () => {
        window.removeEventListener('mousemove', resize);
        window.removeEventListener('mouseup', stopResize);
      };

      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopResize);
    };

    const detailsComponent = computed(() => {
      const viewConfig = newsStore.viewTypeConfig[props.viewType]
      return viewConfig?.detailsComponent || 'NewsDetailsComponent'
    })

    onMounted(async () => {
      newsStore.setViewType(props.viewType)
      
      dataStore.setDiseaseFromRoute("dme")
      await dataStore.fetchConfig()
      
      const doid = dataStore.getDiseaseConfig.doid
      if (!newsStore.loadedDiseases.has(doid)) {
        await newsStore.fetchNews(doid)
      }
    })

    return {
      newsStore,
      handleRowClick,
      detailsPanelWidth,
      startResize,
      headerTitle,
      detailsComponent
    }
  }
}
</script>

<style scoped>
.news-component {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto 300px minmax(0, 1fr) auto;
  height: calc(100vh - 60px);
  position: relative;
}

.filter-panel {
  overflow-y: auto;
  height: 100%;
  
  /* Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.05) transparent;
  transition: scrollbar-color 0.3s ease;
}

/* Default scrollbar state - 95% transparent with transition */
.filter-panel::-webkit-scrollbar {
  width: 20px;
}

.filter-panel::-webkit-scrollbar-track {
  background: transparent;
}

.filter-panel::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 5px solid transparent;
  background-clip: padding-box;
  transition: background-color 0.3s ease;
}

/* Hover state - more visible */
.filter-panel:hover {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  transition: scrollbar-color 0.3s ease;
}

.filter-panel:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.filter-panel:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.table-panel {
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.details-panel {
  position: relative;
  transition: width 0.05s;
  overflow-y: auto;
  background: white;
  border-left: 1px solid #eee;
  
  /* Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.05) transparent;
  transition: scrollbar-color 0.3s ease;
}

/* WebKit browsers */
.details-panel::-webkit-scrollbar {
  width: 20px;
}

.details-panel::-webkit-scrollbar-track {
  background: transparent;
}

.details-panel::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 5px solid transparent;
  background-clip: padding-box;
  transition: background-color 0.3s ease;
}

/* Add these new hover states */
.details-panel:hover {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  transition: scrollbar-color 0.3s ease;
}

.details-panel:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.details-panel:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  cursor: col-resize;
  background-color: #f4f4f4;
  transition: background-color 0.2s;
}

.resize-handle:hover {
  background-color: #d0d0d0;
}

.close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  padding: 0.5rem;
  line-height: 1;
  border-radius: 50%;
  z-index: 1;
}

.close-button:hover {
  background-color: #f0f0f0;
}
</style>
