<template>
  <div class="details-component">
    <div>
      <h2>{{ selectedRow.brief_title }}</h2>
      
      <div class="meta-info">
        <span>
          <i class="fas fa-flask"></i>
          {{ selectedRow.phase }}
        </span>
        <span>
          <i class="fas fa-building"></i>
          {{ selectedRow.sponsor }}
        </span>
        <span v-if="selectedRow.completion_date">
          <i class="fas fa-calendar"></i>
          {{ selectedRow.completion_date }}
        </span>
        <span>
          <i class="fas fa-hashtag"></i>
          {{ selectedRow.nct_id }}
        </span>
      </div>

      <div class="details-grid">
        <!-- Drugs section -->
        <div class="info-item" v-if="selectedRow.drug_names">
          <label>Drugs</label>
          <span>
            <span v-for="drug in selectedRow.drug_names" :key="drug" class="tag-item clickable"
                  :class="{ active: filterValues.drug_names?.includes(drug) }"
                  @click="addToFilter('drug_names', drug)">
              {{ drug }}
            </span>
            <span v-if="selectedRow.annotations_auto?.other_names?.length" class="other-names">
            </span>
          </span>
        </div>

        <!-- Study characteristics -->
        <div class="info-item">
          <label>Study Characteristics</label>
          <div class="characteristics-grid">
            <span><strong>Open Label:</strong> {{ selectedRow.annotations_auto?.openlabel ? 'Yes' : 'No' }}</span>
            <span><strong>Randomized:</strong> {{ selectedRow.annotations_auto?.randomized ? 'Yes' : 'No' }}</span>
            <span><strong>Placebo:</strong> {{ selectedRow.annotations_auto?.placebo ? 'Yes' : 'No' }}</span>
            <span><strong>Double Blind:</strong> {{ selectedRow.annotations_auto?.doubleblind ? 'Yes' : 'No' }}</span>
          </div>
        </div>

        <!-- Eligibility table -->
        <div class="info-item" v-if="selectedRow.annotations_auto?.eligibility">
          <label>Eligibility Criteria</label>
          <table class="eligibility-table">
            <tbody>
              <tr v-if="selectedRow.annotations_auto.eligibility.primary_diagnosis">
                <td>Primary Diagnosis</td>
                <td>{{ selectedRow.annotations_auto.eligibility.primary_diagnosis }}</td>
              </tr>
              <tr v-if="selectedRow.annotations_auto.eligibility.age?.min">
                <td>Minimum Age</td>
                <td>{{ selectedRow.annotations_auto.eligibility.age.min }} years</td>
              </tr>
              <tr v-if="selectedRow.annotations_auto.eligibility.visual_acuity">
                <td>Visual Acuity Range</td>
                <td>{{ selectedRow.annotations_auto.eligibility.visual_acuity.min }} - {{ selectedRow.annotations_auto.eligibility.visual_acuity.max }}</td>
              </tr>
              <tr v-if="selectedRow.annotations_auto.eligibility.comment">
                <td>Additional Criteria</td>
                <td>{{ selectedRow.annotations_auto.eligibility.comment }}</td>
              </tr>
              <tr>
                <td>Healthy Volunteers</td>
                <td>{{ selectedRow.annotations_auto.eligibility.healthy_volunteers ? 'Yes' : 'No' }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Study comment -->
        <div class="info-item" v-if="selectedRow.annotations_auto?.comment">
          <label>Study Description</label>
          <p>{{ selectedRow.annotations_auto.comment }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useNewsStore } from '../stores/newsStore'
import { storeToRefs } from 'pinia'
import '@/assets/styles/details-component.css'

export default {
  name: 'TrialDetailsComponent',
  
  props: {
    selectedRow: {
      type: Object,
      required: true
    }
  },

  setup() {
    const newsStore = useNewsStore()
    const { filterValues } = storeToRefs(newsStore)
    return { filterValues }
  },

  methods: {
    addToFilter(filterName, value) {
      const newsStore = useNewsStore()
      const currentValues = this.filterValues[filterName] || []
      
      if (currentValues.includes(value)) {
        newsStore.updateFilterValues(filterName, currentValues.filter(v => v !== value))
      } else {
        newsStore.updateFilterValues(filterName, [...currentValues, value])
      }
    }
  }
}
</script> 

<style scoped>
.meta-info {
  margin: 10px 0 20px;
  color: #666;
}

.meta-info > span {
  margin-right: 20px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
</style> 