<template>
  <div class="disease-view">
    <ViewHeader :title="diseaseName">
      <template #buttons>
        <div class="dropdown" @mouseleave="handleDropdownLeave" v-if="hasAnalyses">
          <button class="action-btn" @click="toggleAnalysesDropdown">
            <i class="fas fa-list-alt"></i>
            Explore Example Analyses
          </button>
          <div v-if="showAnalysesDropdown" class="dropdown-content">
            <ul>
              <li
                v-for="analysis in dataStore.getAnalyses"
                :key="analysis.name"
                @click="applyAnalysis(analysis)"
              >
                {{ analysis.name }}
              </li>
            </ul>
          </div>
        </div>
        <button class="action-btn" @click="showColumnModal = true">
          <i class="fas fa-columns"></i>
          Column Visibility
        </button>
        <button class="action-btn" @click="toggleDetailsPanel">
          <i class="fas fa-chart-line"></i>
          {{ isDetailsPanelVisible ? 'Hide Chart' : 'Show Chart' }}
        </button>
        
        <ColumnVisibilityModal 
          v-model:show="showColumnModal"
        />
      </template>
    </ViewHeader>
    <div class="content">
      <NavigationSidebar 
        currentView="disease"
        :currentDisease="disease"
      />
      <div class="filter-panel">
        <FilterControls :store="dataStore" />
      </div>
      <div class="table-panel">
        <DataTable :store="dataStore" />
      </div>
      <div class="resize-handle" 
           v-if="isDetailsPanelVisible"
           :style="{ right: detailsPanelWidth + 'px' }" 
           @mousedown="startResize"></div>
      <div class="details-panel" 
           v-if="isDetailsPanelVisible"
           :style="{ width: detailsPanelWidth + 'px' }">
        <DetailsComponent />
        <ChartComponent />
        <TimeSeriesChartComponent v-if="showTimeSeriesChart" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { useDataStore } from '../stores/dataStore';
import { storeToRefs } from 'pinia';
import FilterControls from './FilterControls.vue';
import DataTable from './DataTable.vue';
import DetailsComponent from './DetailsComponent.vue';
import ChartComponent from './ChartComponent.vue';
import ColumnVisibilityModal from './ColumnVisibilityModal.vue';
import TimeSeriesChartComponent from './TimeSeriesChartComponent.vue';
import ViewHeader from './ViewHeader.vue';
import NavigationSidebar from './NavigationSidebar.vue';

export default {
  components: {
    ViewHeader,
    FilterControls,
    DataTable,
    DetailsComponent,
    ChartComponent,
    ColumnVisibilityModal,
    TimeSeriesChartComponent,
    NavigationSidebar,
  },
  props: {
    disease: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const dataStore = useDataStore();
    const { isLoading, error } = storeToRefs(dataStore);
    const detailsPanelWidth = ref(800);
    const isDetailsPanelVisible = ref(true);
    const showColumnModal = ref(false);
    const diseaseName = computed(() => dataStore.getDiseaseName);
    const showAnalysesDropdown = ref(false);

    const startResize = (e) => {
      e.preventDefault();
      const startX = e.clientX;
      const startWidth = detailsPanelWidth.value;

      const resize = (moveEvent) => {
        const diff = startX - moveEvent.clientX;
        detailsPanelWidth.value = Math.min(Math.max(300, startWidth + diff), 1400);
      };

      const stopResize = () => {
        window.removeEventListener('mousemove', resize);
        window.removeEventListener('mouseup', stopResize);
      };

      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopResize);
    };

    const downloadData = () => {
      dataStore.exportToCSV();
    };

    const toggleDetailsPanel = () => {
      isDetailsPanelVisible.value = !isDetailsPanelVisible.value;
    };

    const showTimeSeriesChart = computed(() => {
      return dataStore.config?.timeSeriesConfig;
    });

    const toggleAnalysesDropdown = () => {
      showAnalysesDropdown.value = !showAnalysesDropdown.value;
    };

    const handleDropdownLeave = () => {
      setTimeout(() => {
        showAnalysesDropdown.value = false;
      }, 100);
    };

    const applyAnalysis = (analysis) => {
      dataStore.applyAnalysis(analysis);
      showAnalysesDropdown.value = false;
    };

    const hasAnalyses = computed(() => {
      return dataStore.getAnalyses.length > 0;
    });

    const loadDisease = async (disease) => {
      try {
        await dataStore.setDiseaseFromRoute(disease);
        
        const requiresLogin = dataStore.config?.loginRequired;
        
        if (requiresLogin) {
          const urlParams = new URLSearchParams(window.location.search);
          const token = urlParams.get('token');
          
          if (!token) {
            window.location.href = 'https://gosset.ai';
            return;
          }
        }
        
        if (!dataStore.loadedDiseases.has(disease)) {
          await dataStore.fetchData();
          dataStore.loadedDiseases.add(disease);
        }
      } catch (error) {
        console.error('Error loading disease:', error);
      }
    };

    onMounted(() => {
      loadDisease(props.disease);
    });

    watch(() => props.disease, (newDisease) => {
      loadDisease(newDisease);
    });

    watch(() => dataStore.selectedRow, (newValue) => {
      if (newValue && !isDetailsPanelVisible.value) {
        isDetailsPanelVisible.value = true;
      }
    });

    return {
      isLoading,
      error,
      detailsPanelWidth,
      startResize,
      downloadData,
      isDetailsPanelVisible,
      toggleDetailsPanel,
      showColumnModal,
      diseaseName,
      showTimeSeriesChart,
      showAnalysesDropdown,
      toggleAnalysesDropdown,
      handleDropdownLeave,
      applyAnalysis,
      dataStore,
      hasAnalyses,
    };
  }
};
</script>

<style scoped>
.disease-view {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto 300px minmax(0, 1fr) auto;
  height: calc(100vh - 60px);
  position: relative;
}

.filter-panel, .table-panel, .details-panel {
  overflow-y: auto;
  height: 100%;
}

.details-panel {
  padding: 1rem;
  transition: width 0.05s;
  overflow-y: auto;
  
  /* Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.05) transparent;
  transition: scrollbar-color 0.3s ease;
}

/* WebKit browsers */
.details-panel::-webkit-scrollbar {
  width: 20px;
}

.details-panel::-webkit-scrollbar-track {
  background: transparent;
}

.details-panel::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 5px solid transparent;
  background-clip: padding-box;
  transition: background-color 0.3s ease;
}

.details-panel:hover {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  transition: scrollbar-color 0.3s ease;
}

.details-panel:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.details-panel:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.table-panel {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.chart-controls {
  margin-bottom: 1rem;
}

select {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  cursor: col-resize;
  background-color: #f4f4f4;
  transition: background-color 0.2s;
}

.resize-handle:hover {
  background-color: #d0d0d0;
}

.filter-panel {
  overflow-y: auto;
  height: 100%;
  
  /* Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.05) transparent;
  transition: scrollbar-color 0.3s ease;
}

/* Default scrollbar state - 95% transparent with transition */
.filter-panel::-webkit-scrollbar {
  width: 20px;
}

.filter-panel::-webkit-scrollbar-track {
  background: transparent;
}

.filter-panel::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 5px solid transparent;
  background-clip: padding-box;
  transition: background-color 0.3s ease;
}

/* Hover state - more visible */
.filter-panel:hover {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  transition: scrollbar-color 0.3s ease;
}

.filter-panel:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.filter-panel:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  z-index: 1000;
  background-color: #f9f9f9;
  min-width: 300px;
  margin-top: 0.25rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  top: 100%;
  left: 0;
  padding: 0.5rem 0;
}

.dropdown::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 10px;
  background: transparent;
}

.dropdown-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-content li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-content li:hover {
  background-color: #f1f1f1;
}

/* Add this new style for the analyses button */
.dropdown .action-btn {
    background-color: #0d6efd;
    color: white;
}

.dropdown .action-btn:hover {
    background-color: #0a58ca;
    color: white;
    border-color: #0a58ca;
}

.dialog-actions {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}
</style>
