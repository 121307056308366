<template>
  <div class="toolbar">
    <h2 class="mb-0">
      <router-link to="/" class="gosset-link">
        <img src="@/assets/logo.png" alt="Gosset Logo" class="gosset-logo">
      </router-link>
    </h2>
    <div class="toolbar-buttons">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.toolbar {
  height: 50px;
  padding: 0 0.75rem;
  background-color: rgb(248, 249, 250);
  border-bottom: 1px solid rgb(222, 226, 230);
  display: flex;
  align-items: center;
}

h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: normal;
}

.gosset-link {
  display: inline-flex;
  align-items: center;
  height: 100%;
  margin-right: 0rem;
}

.gosset-logo {
  max-height: 24px;
  position: relative;
  top: 5px;
  width: auto;
  object-fit: contain;
  transition: filter 0.2s ease;
}

.gosset-link:hover .gosset-logo {
  filter: brightness(0);
}

.toolbar-buttons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0;
  margin-left: auto;
  flex-wrap: wrap;
}

/* Button Styles */
:deep(.action-btn) {
  padding: 4px 12px;
  background-color: transparent;
  color: #0d6efd;
  border: 1px solid #0d6efd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

:deep(.action-btn:hover) {
  background-color: rgba(13, 110, 253, 0.04);
  color: #0a58ca;
  border-color: #0a58ca;
}

/* Dropdown Styles */
:deep(.dropdown) {
  position: relative;
  display: inline-block;
}

:deep(.dropdown-content) {
  position: absolute;
  z-index: 1000;
  background-color: #f9f9f9;
  min-width: 300px;
  margin-top: 0.25rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  top: 100%;
  left: 0;
  padding: 0.5rem 0;
}

.dropdown::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 10px;
  background: transparent;
}

.dropdown-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-content li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-content li:hover {
  background-color: #f1f1f1;
}

/* Colored button variant */
:deep(.dropdown .action-btn) {
  background-color: #0d6efd;
  color: white;
}

:deep(.dropdown .action-btn:hover) {
  background-color: #0a58ca;
  color: white;
  border-color: #0a58ca;
}
</style> 