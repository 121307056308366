<template>
  <div class="news-list">
    <div v-for="article in articles" 
         :key="article._id || article.title" 
         class="news-item">
      <div class="article-header" @click="toggleArticle(article._id || article.title)">
        <div class="article-date" v-if="article.date_time">
          {{ formatDate(article.date_time) }}
        </div>
        <div class="article-title">
          {{ article.title }}
          <span class="expand-icon">{{ isArticleExpanded(article._id || article.title) ? '▼' : '▶' }}</span>
        </div>
      </div>
      <ul class="article-summary" v-if="isArticleExpanded(article._id || article.title)">
        <li v-for="(point, index) in article.summary" :key="index">
          {{ point }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsComponent',
  
  props: {
    articles: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      expandedArticles: new Set()
    }
  },

  methods: {
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString();
    },

    toggleArticle(articleId) {
      if (this.expandedArticles.has(articleId)) {
        this.expandedArticles.delete(articleId)
      } else {
        this.expandedArticles.add(articleId)
      }
    },

    isArticleExpanded(articleId) {
      return this.expandedArticles.has(articleId)
    }
  }
}
</script> 