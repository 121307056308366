<template>
  <div class="login-view">
    <div class="login-container">
      <a href="https://gosset.ai/" target="_blank" rel="noopener noreferrer">
        <img src="@/assets/logo.png" alt="Gosset AI Logo" class="logo">
      </a>
      
      <!-- Show this section only if there's a valid invitation token -->
      <template v-if="hasValidInvitation">
        <h1>Welcome to Gosset</h1>
        <p class="subtitle">Enter your email to continue</p>

        <form @submit.prevent="handleSubmit" class="login-form">
          <div class="form-group">
            <input 
              type="email" 
              v-model="email" 
              placeholder="Email address"
              required
              class="form-control"
              :class="{ 'error': error }"
            >
            <p v-if="error" class="error-message">{{ error }}</p>
          </div>

          <button type="submit" class="btn btn-primary" :disabled="loading">
            <span v-if="loading" class="spinner-border spinner-border-sm me-2"></span>
            Continue
          </button>
        </form>
      </template>

      <!-- Show this section when there's no valid invitation -->
      <template v-else>
        <h1>Gosset AI</h1>
        <p class="subtitle">Gosset is currently invitation-only</p>
        <a 
          href="https://gosset.ai" 
          class="btn btn-primary"
          target="_blank" 
          rel="noopener noreferrer"
        >
          Request Access
        </a>
      </template>

      <a href="https://gosset.ai" target="_blank" rel="noopener noreferrer" class="learn-more-link">
        Learn more at gosset.ai
      </a>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/authStore';

export default {
  name: 'LoginView',
  data() {
    return {
      email: '',
      loading: false,
      error: null,
      hasValidInvitation: false
    }
  },
  created() {
    // Check for invitation token in URL
    const token = this.$route.query.invitation_token;
    this.hasValidInvitation = token == "LN823";
    
    // Redirect to homepage if no valid token
    if (!this.hasValidInvitation) {
      console.log('No valid invitation token found');
    }
  },
  methods: {
    async handleSubmit() {
      if (!this.hasValidInvitation) {
        return;
      }
      const authStore = useAuthStore();
      this.loading = true;
      this.error = null;
      
      try {
        const success = await authStore.login(this.email);
        if (success) {
          this.$router.push('/');
        } else {
          this.error = authStore.getError;
        }
      } catch (err) {
        this.error = 'Login failed. Please try again.';
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.login-view {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('@/assets/bg.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 20px;
}

.login-container {
  background: white;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.logo {
  max-width: 50px;
  height: auto;
  margin-bottom: 1.5rem;
}

h1 {
  font-size: 1.75rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #666;
  margin-bottom: 2rem;
}

.login-form {
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out;
}

.form-control:focus {
  outline: none;
  border-color: #4CB3D8;
  box-shadow: 0 0 0 3px rgba(76, 179, 216, 0.1);
}

.form-control.error {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: left;
}

.btn {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.btn-primary {
  background-color: #4CB3D8;
  border: none;
  color: white;
  transition: background-color 0.15s ease-in-out;
}

.btn-primary:hover:not(:disabled) {
  background-color: #3a90ad;
}

.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.learn-more-link {
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.15s ease-in-out;
}

.learn-more-link:hover {
  color: #333;
  text-decoration: underline;
}
</style>
