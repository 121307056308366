<template>
  <div class="navigation-sidebar" :class="{ 'expanded': isExpanded }" v-if="showSidebar">
    <button 
      class="nav-icon toggle-btn"
      @click="isExpanded = !isExpanded"
      :title="isExpanded ? 'Collapse sidebar' : 'Expand sidebar'"
    >
      <i :class="['fas', isExpanded ? 'fa-chevron-left' : 'fa-chevron-right']"></i>
      <span v-if="isExpanded" class="nav-label">Hide menu</span>
    </button>

    <div class="separator"></div>
    <router-link 
      :to="{ name: 'FundsView' }" 
      class="nav-icon"
      :class="{ active: $route.name === 'FundsView' }"
    >
      <i class="fas fa-money-bill"></i>
      <span v-if="isExpanded" class="nav-label">Funds (VC/PE)</span>
    </router-link>
    
    <router-link 
      :to="{ name: 'InvestorsView'}" 
      class="nav-icon"
      :class="{ active: $route.name === 'InvestorsView' }"
    >
      <i class="fas fa-user-tie"></i>
      <span v-if="isExpanded" class="nav-label">Investors</span>
    </router-link>
    
    <router-link 
      :to="{ name: 'AboutView'}" 
      class="nav-icon"
      :class="{ active: $route.name === 'AboutView' }"
    >
      <i class="fas fa-rocket"></i>
      <span v-if="isExpanded" class="nav-label">About</span>
    </router-link>
    
    <div class="separator"></div>

    <!--button 
      v-if="!currentDisease"
      class="premium-btn nav-icon"
      @click="$emit('premium-click')"
    >
      <i class="fas fa-crown"></i>
      <span v-if="isExpanded" class="nav-label">Explore Gosset Premium</span>
    </button-->

    <div class="disease-title" v-if="isExpanded && currentDisease">
      {{ diseaseTitle }}
    </div>

    <router-link 
      v-if="currentDisease"
      :to="{ name: 'DiseaseView', params: { disease: currentDisease }}" 
      class="nav-icon"
      :class="{ active: $route.name === 'DiseaseView' }"
    >
      <i class="fas fa-chart-column"></i>
      <span v-if="isExpanded" class="nav-label">Results</span>
    </router-link>
    
    <router-link 
      v-if="currentDisease"
      :to="{ name: 'TrialsView', params: { disease: currentDisease }}" 
      class="nav-icon"
      :class="{ active: $route.name === 'TrialsView' }"
    >
      <i class="fas fa-vials"></i>
      <span v-if="isExpanded" class="nav-label">Trials</span>
    </router-link>
    
    <router-link 
      v-if="currentDisease"
      :to="{ name: 'AssetsView', params: { disease: currentDisease }}" 
      class="nav-icon"
      :class="{ active: currentView === 'drugs' || $route.name === 'AssetsView' }"
    >
      <i class="fas fa-pills smaller-icon"></i>
      <span v-if="isExpanded" class="nav-label">Assets</span>
    </router-link>

    <!--router-link 
      :to="{ name: 'CatalystsView', params: { disease: currentDisease }}" 
      class="nav-icon"
      :class="{ active: currentView === 'catalyst' || $route.name === 'CatalystsView' }"
    >
      <i class="fas fa-bolt"></i>
      <span v-if="isExpanded" class="nav-label">Catalysts</span>
    </router-link-->
    <router-link 
      v-if="currentDisease"
      :to="{ name: 'NewsView', params: { disease: currentDisease }}" 
      class="nav-icon"
      :class="{ active: $route.name === 'NewsView' }"
    >
      <i class="fas fa-newspaper"></i>
      <span v-if="isExpanded" class="nav-label">News</span>
    </router-link>
    <router-link 
      v-if="currentDisease"
      :to="{ name: 'DealsView', params: { disease: currentDisease }}" 
      class="nav-icon"
      :class="{ active: currentView === 'deals' || $route.name === 'DealsView' }"
    >
      <i class="fas fa-handshake deals-icon"></i>
      <span v-if="isExpanded" class="nav-label">Deals</span>
    </router-link>

    <div class="spacer"></div>
    <div class="user-profile nav-icon" @click="toggleProfileMenu" ref="profileSection">
      <img :src="userPhotoUrl" class="user-photo" :alt="userFullName">
      <div v-if="isExpanded" class="user-info">
        <div class="user-name">{{ firstName }}</div>
        <div class="user-lastname">{{ lastName }}</div>
      </div>
      
      <!-- New dropdown menu -->
      <div v-show="showProfileMenu" class="profile-dropdown">
        <!--router-link to="/profile/" class="dropdown-item">
          <i class="fas fa-user"></i>
          Profile
        </router-link-->
        <router-link to="/logout/" class="dropdown-item">
          <i class="fas fa-sign-out-alt"></i>
          Logout
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/authStore';

export default {
  name: 'NavigationSidebar',
  props: {
    currentView: {
      type: String,
      required: true
    },
    currentDisease: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      isExpanded: localStorage.getItem('sidebarExpanded') !== 'false',
      showProfileMenu: false
    }
  },
  computed: {
    showSidebar() {
      return true
    },
    diseaseTitle() {
      const titles = {
        'dme': 'Diabetic Macular Edema',
        'tgct': 'TGCT'
      }
      return titles[this.currentDisease] || this.currentDisease
    },
    userPhotoUrl() {
      const authStore = useAuthStore();
      return authStore.getPhotoUrl;
    },
    firstName() {
      const authStore = useAuthStore();
      return authStore.getFirstName;
    },
    lastName() {
      const authStore = useAuthStore();
      return authStore.getLastName;
    },
    userFullName() {
      return `${this.firstName} ${this.lastName}`;
    }
  },
  watch: {
    isExpanded(newValue) {
      localStorage.setItem('sidebarExpanded', newValue)
    }
  },
  methods: {
    toggleProfileMenu() {
      this.showProfileMenu = !this.showProfileMenu;
    },
    closeProfileMenu(event) {
      if (this.$refs.profileSection && !this.$refs.profileSection.contains(event.target)) {
        this.showProfileMenu = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.closeProfileMenu);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeProfileMenu);
  }
}
</script>

<style scoped>
.navigation-sidebar {
  width: 48px;
  transition: width 0.3s ease;
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5rem;
}   

.navigation-sidebar.expanded {
  width: 170px;
}

.nav-icon {
  width: 38px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 9px;
  color: #6c757d;
  margin-bottom: 0.5rem;
  border-radius: 6px;
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
  justify-content: flex-start;
}

.nav-icon:hover {
  background-color: #e9ecef;
  color: #495057;
}

.nav-icon.active {
  color: #0d6efd;
  background-color: #e7f1ff;
}

.nav-icon i {
  font-size: 1.25rem;
  width: 20px;
  text-align: center;
  display: inline-block;
  margin: 0;
}

.expanded .nav-icon {
  width: 150px;
  padding-left: 9px;
}

.nav-label {
  margin-left: 12px;
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.3s ease;
  white-space: nowrap;
  flex: 1;
  text-align: left;
}

.expanded .nav-label {
  opacity: 1;
  transform: translateX(0);
  display: inline;
}

.separator {
  height: 1px;
  background-color: #dee2e6;
  width: 32px;
  margin: 0.5rem 0;
}

.expanded .separator {
  width: 130px;
}

.toggle-btn {
  justify-content: flex-start !important;
  padding-left: 9px !important;
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  width: 38px;
}

.expanded .toggle-btn {
  width: 130px;
}

.toggle-btn i {
  width: 20px;
}

.deals-icon,
.smaller-icon {
  font-size: 1.1rem !important;
}

.disease-title {
  color: #495057;
  font-weight: 700;
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
  white-space: normal;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.premium-btn {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #6c757d;
  font-weight: 500;
  margin: 0.5rem 0;
  cursor: pointer;
  height: auto !important;
  min-height: 40px;
  padding: 8px 9px !important;
  white-space: normal !important;
}

.premium-btn .nav-label {
  white-space: normal !important;
  line-height: 1.2;
}

.premium-btn:hover {
  background-color: #e9ecef;
  color: #495057;
}

.premium-btn i {
  color: inherit;
}

.spacer {
  flex: 1;
  min-height: 20px;
}

.user-profile {
  position: relative;
  cursor: pointer;
  margin-top: auto;
  padding: 8px !important;
  height: auto !important;
  background-color: #f1f3f5;
  border-radius: 8px;
  margin-left: 8px;
  margin-right: 8px;
  width: calc(100% - 16px) !important;
  z-index: 1000;
}

.user-photo {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
}

.user-info {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.1;
}

.user-name {
  font-weight: 600;
  color: #495057;
  font-size: 0.8rem;
}

.user-lastname {
  font-size: 0.75rem;
  color: #6c757d;
  font-weight: 600;
}

.navigation-sidebar:not(.expanded) .user-profile {
  padding-left: 4px !important;
  margin-left: 4px;
  margin-right: 4px;
  width: calc(100% - 8px) !important;
}

.profile-dropdown {
  position: fixed;
  bottom: 8px;
  left: 48px;
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 9999;
  min-width: 200px;
  padding: 4px 0;
  background-color: white !important;
}

.expanded .profile-dropdown {
  left: 170px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: #495057;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.2s;
  white-space: nowrap;
}

.dropdown-item:first-child {
  border-radius: 8px 8px 0 0;
}

.dropdown-item:last-child {
  border-radius: 0 0 8px 8px;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.dropdown-item i {
  margin-right: 8px;
  width: 16px;
  font-size: 0.9rem;
}
</style> 