<template>
  <div class="details-component">
    <div>
      <h2>{{ selectedRow.event_name }}</h2>

      <div class="meta-info">
        <span class="date">
          <i class="fas fa-calendar"></i>
          {{ formatDateRange(selectedRow.dates) }}
        </span>
        <span class="type">
          <i class="fas fa-tag"></i>
          {{ selectedRow.type }}
        </span>
        <span class="phase" v-if="selectedRow.phase">
          <i class="fas fa-flask"></i>
          {{ selectedRow.phase }}
        </span>
      </div>

      <div class="description" v-if="selectedRow.description">
        <h3>Description</h3>
        <p>{{ selectedRow.description }}</p>
      </div>

      <div class="details-grid">
        <div class="info-item" v-if="selectedRow.organization?.name">
          <label>Organization</label>
          <span>
            {{ selectedRow.organization.name }}
            <span v-if="selectedRow.organization.symbol" class="symbol">
              ({{ selectedRow.organization.symbol }})
            </span>
          </span>
        </div>

        <div class="info-item" v-if="selectedRow.study_name">
          <label>Study Name</label>
          <span>{{ selectedRow.study_name }}</span>
        </div>

        <div class="info-item" v-if="selectedRow.drugs?.length">
          <label>Drugs</label>
          <span>{{ selectedRow.drugs.map(d => d.name).join(', ') }}</span>
        </div>

        <div class="info-item" v-if="selectedRow.diseases?.length">
          <label>Conditions</label>
          <span>{{ selectedRow.diseases.join(', ') }}</span>
        </div>
      </div>

      <a v-if="selectedRow.url" 
         :href="selectedRow.url" 
         target="_blank"
         class="source-link">
        <i class="fas fa-external-link-alt"></i>
        View Source
      </a>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'CatalystDetailsComponent',
  
  props: {
    selectedRow: {
      type: Object,
      required: true
    }
  },

  methods: {
    formatDateRange(dates) {
      if (!dates) return 'Date TBD'
      
      const from = format(new Date(dates.from), 'MMM d, yyyy')
      
      if (dates.type === 'exact') {
        return from
      }
      
      if (dates.desc) {
        return dates.desc
      }
      
      const to = format(new Date(dates.to), 'MMM d, yyyy')
      return `${from} - ${to}`
    }
  }
}
</script>

<style scoped>
.details-component {
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
  background: white;
}

.meta-info {
  margin: 10px 0 20px;
  color: #666;
}

.meta-info > span {
  margin-right: 20px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.description {
  margin: 20px 0;
}

.description h3 {
  margin-bottom: 10px;
  color: #333;
}

.description p {
  line-height: 1.5;
}

.details-grid {
  display: grid;
  gap: 20px;
  margin: 20px 0;
}

.info-item {
  display: grid;
  gap: 5px;
}

.info-item label {
  color: #666;
  font-size: 0.9em;
}

.symbol {
  color: #666;
  font-size: 0.9em;
}

.source-link {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
  color: #4CB3D8;
  text-decoration: none;
}

.source-link:hover {
  text-decoration: underline;
}
</style> 