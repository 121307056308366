<template>
  <div class="data-table-container">
    <!-- Add loading overlay -->
    <div v-if="dataStore.isLoading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
    
    <table v-if="rows.length" class="data-table table">
      <thead>
        <tr>
          <th v-for="header in headers" :key="header" @click="sortBy(header)" class="resizable">
            <div class="header-content">
              <div class="header-text">
                <template v-if="isSpecialHeader(getDisplayName(header))">
                  <div class="header-main">{{ getMainHeaderText(getDisplayName(header)) }}</div>
                  <div class="header-sub">{{ getSubHeaderText(getDisplayName(header)) }}</div>
                </template>
                <template v-else>
                  {{ getDisplayName(header) }}
                </template>
              </div>
              <span v-if="sortColumn === header" class="sort-arrow">{{ sortOrder === 'asc' ? '▲' : '▼' }}</span>
            </div>
            <div class="resize-handle"
                 @mousedown.stop="startResize($event, header)"
                 @click.stop></div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in rows" :key="index"
            :class="{ 'selected-row': isRowSelected(row), 'hovered-row': index === hoveredRowIndex }"
            @click="selectRow(row)"
            @mouseover="hoveredRowIndex = index"
            @mouseleave="hoveredRowIndex = null">
          <td v-for="header in headers" :key="header" :class="getColumnClass(header)">
            <template v-if="(header === 'phase' || header === 'latest_phase') && row[header]">
              <PhaseProgressBar :phase="row[header]" />
            </template>
            <template v-else-if="header === 'source_type' && row[header]">
              <div class="source-type">
                <i :class="getSourceTypeIcon(row[header])"></i>
                <a :href="row.source" target="_blank">{{ row[header] }}</a>
              </div>
            </template>
            <template v-else-if="isValueWithUnit(row[header])">
              <div class="value-unit" v-if="row[header].value !== null">
                <span class="value">{{ formatNumericValue(row[header].value) }}</span>
                <span class="unit">{{ row[header].unit }}</span>
              </div>
              <span v-else>-</span>
            </template>
            <template v-else-if="header === 'trial' && row[header]">
              <div class="trial-links">
                <template v-for="(trialId, idx) in row[header]" :key="idx">

                  <a v-if="trialId.includes('NCT')" :href="`https://clinicaltrials.gov/study/${trialId}`" 
                     target="_blank" 
                     @click.stop>{{ trialId }}</a>
                  <span v-else>{{ trialId }}</span>
                  <span v-if="idx < row[header].length - 1">, </span>
                </template>
              </div>
            </template>
            <template v-else-if="header === 'categories'">
              <div class="category-tags">
                <span v-for="category in row[header]" 
                      :key="category" 
                      class="category-tag"
                      :style="{ backgroundColor: getCategoryColor(category) }"
                      @click.stop="addCategoryFilter(category)">
                  <i :class="['fas', getCategoryIcon(category)]"></i>
                  <span class="category-text">{{ category }}</span>
                </span>
              </div>
            </template>
            <template v-else-if="header === 'deal_type' && row[header]">
              <div class="deal-tag-container">
                <span v-for="(deal, idx) in row[header]" :key="idx" class="deal-tag"
                      :style="{ backgroundColor: getDealTypeColor(deal) }">
                  <i :class="['fas', getDealTypeIcon(deal)]"></i>
                  <span class="deal-text">{{ deal }}</span>
                </span>
              </div>
            </template>
            <template v-else>
              <span :class="{ 'numeric': isNumericColumn(header) && isNumeric(row[header]) }"
                    v-html="formatValue(row[header], header)">
              </span>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-else>No data available</p>
  </div>
</template>

<script>
import { computed, ref, onMounted, onUnmounted, watch, nextTick } from 'vue'
import PhaseProgressBar from './PhaseProgressBar.vue'

export default {
  name: 'DataTable',
  components: {
    PhaseProgressBar
  },
  props: {
    store: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const dataStore = computed(() => props.store)
    const sortColumn = ref(null)
    const sortOrder = ref(null)
    const hoveredRowIndex = ref(null)

    // Update rows computed property to use filteredData instead of data
    const rows = computed(() => dataStore.value.filteredData)
    const headers = computed(() => {
      const mandatoryColumns = new Set([
        ...dataStore.value.visibleColumns,
        ...getActiveFilterColumns(),
        ...getPlotVariableColumns()
      ]);
      return Array.from(mandatoryColumns);
    })

    const sortedRows = computed(() => {
      if (sortColumn.value) {
        return [...rows.value].sort((a, b) => {
          const aVal = a[sortColumn.value]
          const bVal = b[sortColumn.value]
          
          // Handle null/undefined values
          const isANull = !aVal || (isValueWithUnit(aVal) && aVal.value === null)
          const isBNull = !bVal || (isValueWithUnit(bVal) && bVal.value === null)
          
          // Special handling for phase and latest_phase columns
          if (sortColumn.value === 'phase' || sortColumn.value === 'latest_phase') {
            // Custom phase ordering function
            const getPhaseOrder = (phase) => {
              if (!phase) return -1 // Nulls at bottom
              const phaseMap = {
                'Approved': 100,
                'NDA': 90,
                'Phase 4': 80,
                '4': 80,
                'Phase 3': 70,
                '3': 70,
                'Phase 2': 60,
                '2': 60,
                'Phase 1': 50,
                '1': 50,
                'Preclinical': 40,
                '0': 45,
                'Discontinued': 30,
                'Terminated': 20,
              }
              return phaseMap[phase] || -1
            }
            
            const aOrder = getPhaseOrder(aVal)
            const bOrder = getPhaseOrder(bVal)
            
            return sortOrder.value === 'asc' ? 
              aOrder - bOrder : 
              bOrder - aOrder
          }
          
          // Rest of existing sorting logic...
          if (isANull && isBNull) return 0
          if (isANull) return 1
          if (isBNull) return -1
          
          // Special handling for date columns
          if (['publication_date', 'completion_date'].includes(sortColumn.value)) {
            const aDate = new Date(aVal)
            const bDate = new Date(bVal)
            if (aDate < bDate) return sortOrder.value === 'asc' ? -1 : 1
            if (aDate > bDate) return sortOrder.value === 'asc' ? 1 : -1
            return 0
          }
          
          // Handle objects with value and unit properties
          if (isValueWithUnit(aVal) && isValueWithUnit(bVal)) {
            // Compare units first (case insensitive)
            const aUnit = (aVal.unit || '').toLowerCase()
            const bUnit = (bVal.unit || '').toLowerCase()
            if (aUnit !== bUnit) {
              return sortOrder.value === 'asc' 
                ? aUnit.localeCompare(bUnit)
                : bUnit.localeCompare(aUnit)
            }
            
            // If units are the same, compare values
            const aNum = parseFloat(aVal.value || 0)
            const bNum = parseFloat(bVal.value || 0)
            if (aNum < bNum) return sortOrder.value === 'asc' ? -1 : 1
            if (aNum > bNum) return sortOrder.value === 'asc' ? 1 : -1
            return 0
          }
          
          // Original sorting logic for other columns
          const aNum = parseFloat(aVal)
          const bNum = parseFloat(bVal)
          
          if (!isNaN(aNum) && !isNaN(bNum)) {
            if (aNum < bNum) return sortOrder.value === 'asc' ? -1 : 1
            if (aNum > bNum) return sortOrder.value === 'asc' ? 1 : -1
            return 0
          }
          
          // Case insensitive string comparison
          const aStr = String(aVal).toLowerCase()
          const bStr = String(bVal).toLowerCase()
          if (aStr < bStr) return sortOrder.value === 'asc' ? -1 : 1
          if (aStr > bStr) return sortOrder.value === 'asc' ? 1 : -1
          return 0
        })
      }
      return rows.value
    })

    const sortBy = (column) => {
      if (sortColumn.value === column) {
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc'
      } else {
        sortColumn.value = column
        sortOrder.value = 'asc'
      }
    }

    const isRowSelected = (row) => {
      return dataStore.value.selectedRow && 
             JSON.stringify(dataStore.value.selectedRow) === JSON.stringify(row);
    }

    const selectRow = (row) => {
      if (isRowSelected(row)) {
        // Deselect the row
        dataStore.value.setSelectedRow(null)
      } else {
        // Select the row
        dataStore.value.setSelectedRow(row)
      }
//      emit('row-click', row)
    }

    const getColumnClass = (header) => {
      if (header === 'phase') return 'column-phase'
      if (header === 'trial') return 'column-trial'
      if (header === 'drug_name') return 'column-drug-name'
      if (['mechanisms', 'other_names', "sponsor"].includes(header)) return 'column-align-left'
      if (header === 'publication_date' || header === 'completion_date') return 'column-date'
      return ''
    }

    const getSourceTypeIcon = (sourceType) => {
      const icons = {
        'Publication': 'fa-book',
        'Clinicaltrials.gov': 'fa-hospital',
        'Company materials': 'fa-building',
        'Conference': 'fa-users',
        'Web': 'fa-globe',
        'Presentation': 'fa-chalkboard',
        'Poster': 'fa-file-image',
        'Press Release': 'fa-newspaper',
        'Patent': 'fa-certificate',
        'Company Report': 'fa-file-lines',
        'SEC Filing': 'fa-file-contract',
        'FDA Label': 'fa-prescription-bottle',
        'Website': 'fa-globe',
        'default': 'fa-question-circle'
      }
      return `fas ${icons[sourceType] || icons.default}`
    }

    const getDisplayName = (header) => {
      return dataStore.value.getDisplayName(header);
    }

    const formatDollarValue = (value) => {
      if (!value) return '-'
      
      // Convert to millions/billions
      if (value >= 1e9) {
        return `$${(value / 1e9).toFixed(1)}B`
      } else if (value >= 1e6) {
        return `$${(value / 1e6).toFixed(1)}M`
      } else if (value >= 1e3) {
        return `$${(value / 1e3).toFixed(1)}K`
      }
      
      return `$${value}`
    }

    const formatValue = (value, header) => {
      if (value === null || value === undefined) return '-'
      
      // Handle boolean values with colored icons
      if (typeof value === 'boolean') {
        return value ? 
          '<span class="boolean-yes"><i class="fas fa-check"></i> Yes</span>' : 
          '<span class="boolean-no"><i class="fas fa-times"></i> No</span>'
      }
      
      // Check if it's a dollar or million_dollars type column
      const type = dataStore.value.getVariableType?.(header)
      if (type === 'dollar') {
        return formatDollarValue(value)
      }
      if (type === 'million_dollars') {
        return formatMillionDollarValue(value)
      }
      
      // Existing formatting logic...
      if (Array.isArray(value)) {
        return value.join(', ')
      }
      if (value !== null && typeof value === 'object' && !Array.isArray(value) && !isValueWithUnit(value)) {
        return '-'
      }
      return value
    }

    // Add new helper function for million dollar formatting
    const formatMillionDollarValue = (value) => {
      if (!value) return '-'
      
      // Convert to millions
      const millions = value / 1e6
      // Format with commas and 1 decimal place
      return `$${millions.toLocaleString('en-US', { 
        minimumFractionDigits: 1,
        maximumFractionDigits: 1 
      })}M`
    }

    // Add new method to handle keyboard navigation
    const handleKeyDown = (event) => {
      if (!sortedRows.value.length) return
      
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault()
        
        const currentIndex = dataStore.value.selectedRow 
          ? sortedRows.value.indexOf(dataStore.value.selectedRow)
          : -1
        
        let newIndex
        if (event.key === 'ArrowUp') {
          newIndex = currentIndex <= 0 ? sortedRows.value.length - 1 : currentIndex - 1
        } else {
          newIndex = currentIndex >= sortedRows.value.length - 1 ? 0 : currentIndex + 1
        }
        
        selectRow(sortedRows.value[newIndex])
      }
    }

    // Add onMounted to set up event listener
    onMounted(() => {
      window.addEventListener('keydown', handleKeyDown)
    })

    // Add onUnmounted to clean up
    onUnmounted(() => {
      window.removeEventListener('keydown', handleKeyDown)
    })

    // Add new method to handle scrolling
    const scrollToSelectedRow = () => {
      if (!dataStore.value.selectedRow) return
      
      // Find the DOM element of the selected row
      const selectedElement = document.querySelector('.selected-row')
      if (!selectedElement) return

      // Get the container element
      const container = document.querySelector('.data-table-container')
      if (!container) return

      // Calculate positions
      const containerRect = container.getBoundingClientRect()
      const selectedRect = selectedElement.getBoundingClientRect()
      
      // Calculate scroll position to center the row
      const scrollTop = container.scrollTop + 
        selectedRect.top - 
        containerRect.top - 
        (containerRect.height / 2) + 
        (selectedRect.height / 2)

      // Smooth scroll to position
      container.scrollTo({
        top: scrollTop,
        behavior: 'smooth'
      })
    }


    // Add helper functions
    const getActiveFilterColumns = () => {
      const activeColumns = [];
      
      // Add columns with active multichoice filters
      Object.entries(dataStore.value.filterValues).forEach(([column, values]) => {
        if (values && values.length > 0) {
          activeColumns.push(column);
        }
      });
      
      // Add columns with active range filters
      Object.entries(dataStore.value.selectedRanges).forEach(([column, range]) => {
        if (range && (range.min !== null || range.max !== null)) {
          activeColumns.push(column);
        }
      });
      
      return activeColumns;
    };

    const getPlotVariableColumns = () => {
      const plotColumns = [];
      
      // Add X variable if selected
      if (dataStore.value.selectedXVar) {
        plotColumns.push(dataStore.value.selectedXVar);
      }
      
      // Add Y variable if selected
      if (dataStore.value.selectedYVar) {
        plotColumns.push(dataStore.value.selectedYVar);
      }
      
      return plotColumns;
    };

    const isValueWithUnit = (value) => {
      return value && typeof value === 'object' && (
        // Check for both value/unit structure and null values
        ('value' in value && 'unit' in value) ||
        // Also return true for endpoint objects that might have null values
        Object.prototype.hasOwnProperty.call(value, 'value')
      );
    }

    const isNumeric = (value) => {
      return !isNaN(parseFloat(value)) && isFinite(value);
    }

    const formatNumericValue = (value) => {
      if (value === null || value === undefined) return '-';
      if (Array.isArray(value)) {
        return value.join(', ')
      }
      if (typeof value === 'object' && !Array.isArray(value)) {
        return Object.entries(value)
          .map(([key, val]) => `${val} (${key})`)
          .join(', ')
      }
      if (!isNumeric(value)) return value;
      // Format to 2 decimal places for endpoint values
      return Number.isInteger(value) ? value.toString() : Number(value).toFixed(2);
    }

    const isSpecialHeader = (header) => {
      return header.includes(' - ') || header.includes('(');
    }

    const getMainHeaderText = (header) => {
      return header.split('(')[0].trim();
    }

    const getSubHeaderText = (header) => {
      const match = header.match(/\((.*?)\)/);
      return match ? match[1] : '';
    }

    watch(() => dataStore.value.selectedRow, (newSelectedRow) => {
      if (newSelectedRow) {
        // Use nextTick to ensure DOM is updated before scrolling
        nextTick(() => {
          scrollToSelectedRow();
        });
      }
    }, { immediate: true });

    // Add new refs for resizing
    const isResizing = ref(false)
    const currentResizeHeader = ref(null)
    const startX = ref(0)
    const startWidth = ref(0)

    const startResize = (event, header) => {
      isResizing.value = true
      currentResizeHeader.value = header
      startX.value = event.pageX
      
      // Get the current width of the column
      const headerCell = event.target.parentElement
      startWidth.value = headerCell.offsetWidth
      
      // Add event listeners
      document.addEventListener('mousemove', handleResize)
      document.addEventListener('mouseup', stopResize)
      
      // Add class to prevent text selection while resizing
      document.body.classList.add('resizing')
    }

    const handleResize = (event) => {
      if (!isResizing.value) return
      
      const diff = event.pageX - startX.value
      const newWidth = Math.max(100, startWidth.value + diff) // Minimum width of 100px
      
      // Find all cells in this column and update their width
      const cells = document.querySelectorAll(
        `th:nth-child(${Array.from(headers.value).indexOf(currentResizeHeader.value) + 1}), 
         td:nth-child(${Array.from(headers.value).indexOf(currentResizeHeader.value) + 1})`
      )
      
      cells.forEach(cell => {
        cell.style.width = `${newWidth}px`
        cell.style.minWidth = `${newWidth}px`
      })
    }

    const stopResize = () => {
      isResizing.value = false
      currentResizeHeader.value = null
      document.removeEventListener('mousemove', handleResize)
      document.removeEventListener('mouseup', stopResize)
      document.body.classList.remove('resizing')
    }

    const getCategoryIcon = (category) => {
      return props.store.config.categoryIcons[category]?.icon || 'fa-circle';
    }

    const getCategoryColor = (category) => {
      return props.store.config.categoryIcons[category]?.color || '#6c757d';
    }

    const addCategoryFilter = (category) => {
      // Get current filter values for categories
      const currentValues = new Set(dataStore.value.filterValues['categories'] || []);
      
      // Add the new category if it's not already there
      if (!currentValues.has(category)) {
        currentValues.add(category);
        // Update the store with the new filter values
        dataStore.value.updateFilterValues('categories', Array.from(currentValues));
      }
    }

    const getDealTypeIcon = (dealType) => {
      if (typeof dealType === 'string') {
        const lowercaseType = dealType.toLowerCase();
        return props.store.config.dealTypeIcons[lowercaseType]?.icon || props.store.config.dealTypeIcons.default.icon;
      } else {
        console.warn('dealType is not a string:', dealType);
        return '';
      }
    }

    const getDealTypeColor = (dealType) => {
      if (typeof dealType === 'string') {
        const lowercaseType = dealType.toLowerCase();
        return props.store.config.dealTypeIcons[lowercaseType]?.color || '#6c757d';
      } else {
        console.warn('dealType is not a string:', dealType);
        return '#6c757d';
      }
    }

    const isNumericColumn = (header) => {
      const type = dataStore.value.getVariableType?.(header)
      return type !== 'string' // Only apply numeric class if not a string type
    }

    // Add a watch effect to handle default sorting when data changes
    watch(
      [() => rows.value, () => dataStore.value.getSortConfig],
      ([newRows, newSortConfig]) => {
        // Only apply default sort if no manual sort is active
        if (!sortColumn.value && newRows.length > 0) {
          sortColumn.value = newSortConfig.column
          sortOrder.value = newSortConfig.order
        }
      },
      { immediate: true }
    )

    // Add a reset function to clear manual sorting
    const resetSort = () => {
      sortColumn.value = null
      sortOrder.value = null
    }

    // Watch for view type changes to reset sorting
    watch(
      () => dataStore.value.currentViewType,
      () => {
        resetSort()
      }
    )

    return {
      rows: sortedRows,
      headers,
      getDisplayName,
      sortColumn,
      sortOrder,
      sortBy,
      hoveredRowIndex,
      selectRow,
      getColumnClass,
      getSourceTypeIcon,
      dataStore: computed(() => props.store),
      formatValue,
      scrollToSelectedRow,
      isValueWithUnit,
      isNumeric,
      formatNumericValue,
      isSpecialHeader,
      getMainHeaderText,
      getSubHeaderText,
      startResize,
      getCategoryIcon,
      getCategoryColor,
      addCategoryFilter,
      isRowSelected,
      getDealTypeIcon,
      getDealTypeColor,
      formatDollarValue,
      isNumericColumn,
    }
  }
}
</script>

<style scoped>
.data-table-container {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: auto;
}

/* Default scrollbar state - 95% transparent with transition */
.data-table-container::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

.data-table-container::-webkit-scrollbar-track {
  background: transparent;
}

.data-table-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 5px solid transparent;
  background-clip: padding-box;
  transition: background-color 0.3s ease;
}

/* Hover state - more visible */
.data-table-container:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.data-table-container:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

/* For Firefox */
.data-table-container {
  scrollbar-color: rgba(0, 0, 0, 0.05) transparent;
  transition: scrollbar-color 0.3s ease;
}

.data-table-container:hover {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  transition: scrollbar-color 0.3s ease;
}

.data-table {
  width: 100%;
  height: 100%;
}

.table {
  font-size: 0.9rem;
  width: 100%;
  table-layout: auto;
  height: auto; /* Remove height: 100% to prevent stretching */
}

.table th, .table td {
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100px;
  max-width: 250px; /* Add maximum width constraint */
  max-height: 2rem;
  height: 2rem;
}

.selected-row {
  background-color: #fffacd !important;
  font-weight: bold;
}

.table th {
  cursor: pointer;
  user-select: none;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  text-align: center;
}

.table th:hover {
  background-color: #f0f0f0;
}

/* ... Add the rest of the provided styles ... */

.source-type {
  display: flex;
  align-items: center;
  gap: 8px;  /* Add padding between icon and text */
}

.source-type i {
  color: #3498db;  /* Light blue color for the icon */
}

/* Remove scoped attribute from link styles since we want them global */
:deep(.source-type a) {
  color: #3498db;
  text-decoration: none;
}

:deep(.source-type a:hover) {
  text-decoration: underline;
}

.table tbody tr {
  cursor: pointer;
}

.value-unit {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 4px;
  min-width: 100px; /* Ensure minimum width for value-unit pairs */
}

.value {
  text-align: right;
  min-width: 60px; /* Ensure minimum width for values */
}

.unit {
  color: #666;
  font-size: 0.85em;
  min-width: 30px; /* Ensure minimum width for units */
  text-align: left;
}

.numeric {
  display: block;
  text-align: right;
}

.table td {
  /* ... existing styles ... */
  text-align: left;
}


.table td.column-drug-name {
  text-align: left;
  max-width: 200px; /* Ensure max-width is applied to drug name column */
}

.table td:has(.source-type) {
  text-align: left;
}

.table td.column-phase {
  text-align: center;
}

.trial-links {
  text-align: left;
}

.trial-links a {
  color: #3498db;
  text-decoration: none;
}

.trial-links a:hover {
  text-decoration: underline;
}

.table td.column-date {
  text-align: left !important; /* Add !important to override numeric text alignment */
}

.table td.column-date .numeric {
  text-align: left !important; /* Override numeric class alignment for dates */
  display: inline; /* Change display to inline to prevent block behavior */
}

.header-main {
  font-size: 0.9em;
  line-height: 1.2;
  text-align: center;
}

.header-sub {
  font-size: 0.75em;
  color: #666;
  line-height: 1.1;
  text-align: center;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-text {
  flex-grow: 1;
}

.sort-arrow {
  margin-left: 8px;
}

.table td.column-drug-name,
.table td:has(.source-type),
.table td:has(.trial-links),
.table td.column-date,
.table td:has(.mechanisms),  /* Add this selector */
.table td.column-align-left {  /* Add this selector as fallback */
  text-align: left;
}

.resizable {
  position: relative;
}

.resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  cursor: col-resize;
  background-color: transparent;
}

.resize-handle:hover {
  background-color: #ddd;
}

/* Add to prevent text selection while resizing */
:global(body.resizing) {
  cursor: col-resize;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

:global(body.resizing .table th) {
  cursor: col-resize;
}

/* Add these new styles for the loading overlay */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.category-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.category-tag {
  display: inline-flex;
  align-items: center;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 0.75em;
  color: #673ab7; /* Dark purple text */
  white-space: nowrap;
  max-width: 100%;
  border: 1px solid rgba(103, 58, 183, 0.1); /* Very subtle purple border */
  cursor: pointer;
  transition: transform 0.1s ease;
}

.category-tag:hover {
  transform: scale(1.05);
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.category-tag i {
  margin-right: 4px;
  font-size: 0.9em;
  opacity: 0.7; /* Make icons slightly more subtle */
}

.category-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Update table cell padding for categories */
.table td:has(.category-tags) {
  padding: 4px 8px;
}

.deal-tag-container {
  display: flex;
  align-items: center;
}

.deal-tag {
  display: inline-flex;
  align-items: center;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 0.75em;
  color: #673ab7; /* Dark purple text */
  white-space: nowrap;
  max-width: 100%;
  border: 1px solid rgba(103, 58, 183, 0.1); /* Very subtle purple border */
  cursor: pointer;
  transition: transform 0.1s ease;
}

.deal-tag:hover {
  transform: scale(1.05);
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.deal-tag i {
  margin-right: 4px;
  font-size: 0.9em;
  opacity: 0.7; /* Make icons slightly more subtle */
}

.deal-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Update table cell padding for deal_type */
.table td:has(.deal-tag-container) {
  padding: 4px 8px;
}

/* Add specific style for string columns */
.table td:has(span:not(.numeric)) {
  text-align: left;
}

:deep(.boolean-yes) {
  color: #28a745;
  background-color: rgba(40, 167, 69, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
}

:deep(.boolean-no) {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
}

:deep(.boolean-yes i), :deep(.boolean-no i) {
  margin-right: 4px;
}
</style>
