<template>
    <div class="about-view">
      <ViewHeader title="About" />
      
      <div class="content">
        <NavigationSidebar 
          currentView="about"
          :currentDisease="null"
        />
        
        <div class="about-content">
            <div class="content-container">
          <div class="content-section">
            <h2>About Gosset</h2>
            <p>We build AI agents to help you collect the data you need to make better decisions.</p>
          </div>
    
          <div class="content-section">
            <h2>How it works</h2>
            <div style="margin-bottom: 1rem;">
              Our AI agents navigate the web to collect data.
              This data is then stored in a cloud and displayed in our dashboards.
              You can export the data in any format you need.
            </div>

            <div style="margin-bottom: 1rem;">
              See an example of our agents at work in the video below.
            </div>

            <div class="video-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/GM_IVRh98sY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div class="content-section">
            <h2>I want more!</h2>
            <p>Ready to explore more? Take a look at the comprehensive data we collect for each disease and get in touch with us to learn other ways we can enhance your work. Click below to see one of our full dashboards for an example disease (diabetic macular edema).</p>
            
            <div class="button-container">
              <router-link to="/dme/results" class="action-button primary-button">
                Disease Dashboard
              </router-link>
              
              <a href="mailto:partner@gosset.ai" class="action-button secondary-button">
                Contact Us
              </a>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ViewHeader from './ViewHeader.vue'
  import NavigationSidebar from './NavigationSidebar.vue'
  
  export default {
    name: 'AboutView',
    
    components: {
      ViewHeader,
      NavigationSidebar
    }
  }
  </script>
  
  <style scoped>
  .about-view {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .content {
    display: grid;
    grid-template-columns: auto 1fr;
    height: calc(100vh - 50px);
    overflow: hidden;
  }
  
  .about-content {
    overflow-y: auto;
  }
  
  .content-section {
    margin-bottom: 3rem;
  }
  
  .content-section h2 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  .content-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .content-section li {
    margin: 0.5rem 0;
    padding-left: 1.5rem;
    position: relative;
  }
  
  .content-section li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: #666;
  }
  
  a {
    color: #2c5282;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content-container {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
  }

  .button-container {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
    justify-content: center;
  }

  .action-button {
    display: inline-block;
    padding: 0.5rem 1rem;
    color: #333;
    border-radius: 4px;
    transition: all 0.3s ease;
    text-decoration: none;
    min-width: 120px;
    text-align: center;
  }

  .primary-button {
    background-color: #e3f2fd;
    border: 1px solid #90caf9;
  }

  .secondary-button {
    background-color: #e8f5e9;
    border: 1px solid #a5d6a7;
  }

  .primary-button:hover {
    background-color: #bbdefb;
    text-decoration: none;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  }

  .secondary-button:hover {
    background-color: #c8e6c9;
    text-decoration: none;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  }

  </style>