<template>
  <div class="filter-controls">

    <div v-for="filter in filters" :key="filter.var" class="filter-section">
      <div 
        class="filter-header" 
        :class="{ active: hasActiveFilter(filter.var) }"
        @click="toggleFilter(filter.var)"
      >
        {{ getDisplayName(filter.var) }}
        <i :class="[
          'fas', 
          isFilterExpanded(filter.var) ? 'fa-chevron-up' : 'fa-chevron-down'
        ]"></i>
      </div>
      
      <div 
        class="filter-content"
        :class="{ expanded: isFilterExpanded(filter.var) }"
        v-show="isFilterExpanded(filter.var)"
      >
          <!-- Checkbox filters for boolean values -->
          <div v-if="filter.type === 'boolean'" class="checkbox-container">
            <div v-if="filter.style === 'multichoice'" class="checkbox-group">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  value="true"
                  :checked="isOptionSelected(filter.var, 'true')"
                  @change="updateBooleanFilterCheckbox(filter.var, 'true', $event.target.checked)"
                  class="form-check-input"
                />
                Required
              </label>
              <label class="form-check-label">
                <input
                  type="checkbox"
                  value="false"
                  :checked="isOptionSelected(filter.var, 'false')"
                  @change="updateBooleanFilterCheckbox(filter.var, 'false', $event.target.checked)"
                  class="form-check-input"
                />
                Excluded
              </label>
              <label class="form-check-label">
                <input
                  type="checkbox"
                  value="undefined"
                  :checked="isOptionSelected(filter.var, 'undefined')"
                  @change="updateBooleanFilterCheckbox(filter.var, 'undefined', $event.target.checked)"
                  class="form-check-input"
                />
                Undefined
              </label>
            </div>
            
            <div v-else class="simple-checkbox">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  :checked="isSimpleCheckboxSelected(filter.var)"
                  @change="updateSimpleCheckbox(filter.var, $event.target.checked)"
                  class="form-check-input"
                />
                {{ getDisplayName(filter.var) }}
              </label>
            </div>
          </div>

      <div v-if="filter.type === 'multichoice'">
          <div v-if="filter.style === 'checkbox'" class="checkbox-container">
            <div v-for="option in getFilterOptions(filter.var)" :key="option" class="form-check">
              <label class="form-check-label" v-if="option !== null && option !== ''">
                <input
                  type="checkbox"
                  :value="option"
                  :checked="isOptionSelected(filter.var, option)"
                  @change="updateMultichoiceFilter(filter.var, option)"
                  class="form-check-input"
                />
                {{ option }}
              </label>
            </div>
          </div>
          <div v-else-if="filter.style === 'select'">
            <TagSelect
              v-model="dataStore.filterValues[filter.var]"
              :options="getFilterOptions(filter.var)"
            />
          </div>
        </div>
        
        <div v-else-if="filter.type === 'range'" class="range-filter">
          <div v-if="getVariableType(filter.var) === 'date'" class="date-range">
            <div class="date-range-controls">
              <div class="date-inputs">
                <input 
                  type="date" 
                  :value="formatDate(getSelectedRange(filter.var).min)"
                  @input="updateDateRange(filter.var, 'min', $event.target.value)"
                  :min="formatDate(getFilterRange(filter.var).min)"
                  :max="formatDate(getFilterRange(filter.var).max)"
                  class="form-control"
                />
                <input 
                  type="date" 
                  :value="formatDate(getSelectedRange(filter.var).max)"
                  @input="updateDateRange(filter.var, 'max', $event.target.value)"
                  :min="formatDate(getFilterRange(filter.var).min)"
                  :max="formatDate(getFilterRange(filter.var).max)"
                  class="form-control"
                />
              </div>

            </div>
          </div>
          <div v-else-if="getVariableType(filter.var) === 'int' || getVariableType(filter.var) === 'float'" class="numeric-range">
            <template v-if="getFilterRange(filter.var).min !== null && getFilterRange(filter.var).max !== null">
              <vue-slider
                v-model="rangeValues[filter.var]"
                @change="updateIntRange(filter.var, $event)"
                :min="getFilterRange(filter.var).min"
                :max="getFilterRange(filter.var).max"
                :tooltip="'always'"
                :enable-cross="false"
                :marks="false"
                :interval="calculateInterval(filter.var)"
              />
              <div class="range-values">
                <span>Min: {{ formatRangeValue(rangeValues[filter.var]?.[0]) }}</span>
                <span>Max: {{ formatRangeValue(rangeValues[filter.var]?.[1]) }}</span>
              </div>
            </template>
            <div v-else class="no-range-data">
              No range data available
            </div>
          </div>
        </div>
        <div class="multichoice-buttons">
            <div>
              <button 
                class="btn btn-outline-primary btn-sm" 
                @click="handleClear(filter)"
                :disabled="!hasActiveFilter(filter.var)"
              >
                Clear
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import TagSelect from './TagSelect.vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

// Add props definition
const props = defineProps({
  store: {
    type: Object,
    required: true
  }
});

// Replace direct dataStore usage with computed prop
const dataStore = computed(() => props.store);

const expandedFilters = ref(new Set());

// Add a new ref to cache filter options
const filterOptionsCache = ref({});

// Modify the hasActiveFilter function to also check if filter is expanded
const hasActiveFilter = (variable) => {
  if (expandedFilters.value.has(variable)) {
    return true;
  }
  
  const filter = dataStore.value.config.filters.find(f => f.var === variable);
  
  if (filter?.type === 'boolean') {
    const selectedValues = dataStore.value.filterValues[variable] || [];
    if (filter.style === 'checkbox') {
      return selectedValues.includes('true');
    } else {
      return selectedValues.length > 0;
    }
  }
  
  if (dataStore.value.filterValues[variable]?.length > 0) {
    return true;
  }
  
  const selectedRange = getSelectedRange(variable);
  if (selectedRange.min !== null || selectedRange.max !== null) {
    return true;
  }
  
  return false;
};

const getDisplayName = (variable) => {
  const diseaseParams = dataStore.value.diseaseConfig?.frontend;
  const extraFilter = diseaseParams?.extraFilters?.find(f => f.var === variable);
  if (extraFilter?.displayName) {
    return extraFilter.displayName;
  }
  
  return dataStore.value.getDisplayName(variable);
};

// Helper functions needed by hasActiveFilter
const getSelectedRange = (variable) => {
  return dataStore.value.selectedRanges[variable] || { min: null, max: null };
};

// Now we can safely initialize expanded filters
const initializeExpandedFilters = () => {
  // Watch for changes in filterValues and selectedRanges
  watch(
    [() => dataStore.value.filterValues, () => dataStore.value.selectedRanges],
    () => {
      dataStore.value.config.filters.forEach(filter => {
        if (hasActiveFilter(filter.var)) {
          expandedFilters.value.add(filter.var);
        }
      });
    },
    { immediate: true, deep: true }
  );
};

// Rest of the functions
const isFilterExpanded = (filterVar) => {
  return expandedFilters.value.has(filterVar);
};

const toggleFilter = (filterVar) => {
  if (expandedFilters.value.has(filterVar)) {
    expandedFilters.value.delete(filterVar);
  } else {
    expandedFilters.value.add(filterVar);
  }
};

// Initialize after all functions are defined
initializeExpandedFilters();

const formatDate = (date) => {
  if (!date || !(date instanceof Date) || isNaN(date)) return '';
  try {
    return date.toISOString().split('T')[0];
  } catch (e) {
    console.warn('Invalid date:', date);
    return '';
  }
};

const getFilterRange = (variable) => {
  if (dataStore.value.filterRanges[variable]) {
    return dataStore.value.filterRanges[variable];
  }

  // Calculate range for integer filters
  const values = dataStore.value.data
    .map(item => item[variable])
    .filter(val => val !== null && val !== undefined && !isNaN(val));

  if (values.length === 0) {
    return { min: null, max: null }; // Default range
  }

  return {
    min: Math.floor(Math.min(...values)),
    max: Math.ceil(Math.max(...values))
  };
};

// Modify getFilterOptions to only cache when values are ready
const getFilterOptions = (filterVar) => {
  // Only return cached options if they exist AND are non-empty
  if (filterOptionsCache.value[filterVar]?.length > 0) {
    return filterOptionsCache.value[filterVar];
  }

  // Calculate options
  const values = new Set();
  let hasNullOrUndefined = false;

  dataStore.value.data.forEach(item => {
    const value = item[filterVar];
    
    // Check for null/undefined/empty values
    if (value === null || value === undefined || value === '') {
      hasNullOrUndefined = true;
      return;
    }

    // Handle array values
    if (Array.isArray(value)) {
      value.forEach(v => {
        if (v === null || v === undefined || v === '') {
          hasNullOrUndefined = true;
        } else {
          values.add(v);
        }
      });
    } else {
      values.add(value);
    }
  });

  // Convert Set to array and add "NA" if needed
  let optionsArray = Array.from(values);
  if (hasNullOrUndefined) {
    optionsArray.push("NA");
  }

  // Only cache if we have actual values
  if (optionsArray.length > 0) {
    const sortedValues = filterVar === 'source_type' 
      ? sortSourceType(optionsArray) 
      : defaultSort(optionsArray);
    filterOptionsCache.value[filterVar] = sortedValues;
    return sortedValues;
  }

  return []; // Return empty array but don't cache it
};

// Helper functions for sorting
const sortSourceType = (values) => {
  const customOrder = ['Publication', 'Clinicaltrials.gov', 'Company results', 'No results found', 'NA'];
  return values.sort((a, b) => {
    const indexA = customOrder.indexOf(a);
    const indexB = customOrder.indexOf(b);
    if (indexA !== -1 && indexB !== -1) return indexA - indexB;
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;
    return String(a).localeCompare(String(b));
  });
};

const defaultSort = (values) => {
  return values.sort((a, b) => {
    if (a === "NA") return 1;  // Move "NA" to the end
    if (b === "NA") return -1;
    if (!isNaN(a) && !isNaN(b)) return Number(a) - Number(b);
    return String(a).localeCompare(String(b));
  });
};

const isOptionSelected = (filterVar, option) => {
  const selectedValues = dataStore.value.filterValues[filterVar] || [];
  return selectedValues.includes(option);
};

const updateMultichoiceFilter = (filterVar, option) => {
  const currentValues = dataStore.value.filterValues[filterVar] || [];
  const newValues = currentValues.includes(option)
    ? currentValues.filter(v => v !== option)
    : [...currentValues, option];
  dataStore.value.updateFilterValues(filterVar, newValues);
};

const updateDateRange = (variable, bound, value) => {
  const currentRange = getSelectedRange(variable);
  const newRange = {
    ...currentRange,
    [bound]: new Date(value)
  };
  dataStore.value.updateDateRange(variable, newRange);
};

const handleClear = (filter) => {
  if (filter.type === 'multichoice') {
    dataStore.value.updateFilterValues(filter.var, []);
  } else if (filter.type === 'range') {
    dataStore.value.updateDateRange(filter.var, {
      min: null,
      max: null
    });
  } else if (filter.type === 'boolean') {
    // Clear boolean filter values
    dataStore.value.updateFilterValues(filter.var, []);
  }
};

// Add new ref for range values
const rangeValues = ref({});

// Modify initializeRangeValues to only set values when they're valid
const initializeRangeValues = () => {
  dataStore.value.config.filters
    .filter(f => f.type === 'range' && 
      (dataStore.value.config.variableType[f.var] === 'int' || 
       dataStore.value.config.variableType[f.var] === 'float'))
    .forEach(filter => {
      const range = getFilterRange(filter.var);
      const selectedRange = getSelectedRange(filter.var);
      
      // Only set values if we have valid range values AND they're not null/undefined
      if (range.min !== null && range.max !== null && 
          !isNaN(range.min) && !isNaN(range.max) &&
          range.min !== undefined && range.max !== undefined) {
        rangeValues.value[filter.var] = [
          selectedRange.min ?? range.min,
          selectedRange.max ?? range.max
        ];
      } else {
        // Remove any existing invalid cached values
        delete rangeValues.value[filter.var];
      }
    });
};

// Add new function to handle integer range updates
const updateIntRange = (variable, value) => {
  dataStore.value.updateRange(variable, {
    min: value[0],
    max: value[1]
  });
};

// Initialize ranges after component mount
initializeRangeValues();

const getVariableType = (variable) => {
  return dataStore.value.getVariableType(variable);
};

// Add new helper functions
const calculateInterval = (variable) => {
  const range = getFilterRange(variable);
  const type = getVariableType(variable);
  const diff = range.max - range.min;
  
  if (type === 'int') {
    return 1;
  }
  
  // For floats, calculate a suitable interval that divides the range evenly
  // Aim for roughly 100 steps across the range
  const magnitude = Math.floor(Math.log10(diff / 100));
  const interval = Math.pow(10, magnitude);
  return interval;
};

const formatRangeValue = (value) => {
  if (value === undefined || value === null || isNaN(value)) return '-';
  const numValue = Number(value);
  return Number.isInteger(numValue) ? numValue.toString() : numValue.toFixed(2);
};

// Add a watch to update rangeValues when filterRanges changes
watch(
  () => dataStore.value.filterRanges,
  () => {
    initializeRangeValues();
  },
  { deep: true }
);

// Add new computed property for non-population filters
const filters = computed(() => dataStore.value.getFilters);

const updateBooleanFilterCheckbox = (filterVar, option, checked) => {
  let currentValues = dataStore.value.filterValues[filterVar] || [];
  if (checked) {
    // Add the option if not already selected
    if (!currentValues.includes(option)) {
      currentValues.push(option);
    }
  } else {
    // Remove the option if it was selected
    currentValues = currentValues.filter(v => v !== option);
  }
  dataStore.value.updateFilterValues(filterVar, currentValues);
};

// Modify the watch for data changes to only cache when data is ready
watch(() => dataStore.value.data, (newData) => {
  if (newData && newData.length > 0) {
    // Clear existing cache first
    filterOptionsCache.value = {};
    
    // Pre-calculate all filter options when data is available
    dataStore.value.config.filters.forEach(filter => {
      if (filter.type === 'multichoice') {
        const options = getFilterOptions(filter.var);
        if (options.length > 0) {
          filterOptionsCache.value[filter.var] = options;
        }
      }
    });
  }
}, { immediate: true });

// Add new helper functions for simple checkbox
const isSimpleCheckboxSelected = (filterVar) => {
  const selectedValues = dataStore.value.filterValues[filterVar] || [];
  return selectedValues.includes('true');
};

const updateSimpleCheckbox = (filterVar, checked) => {
  if (checked) {
    dataStore.value.updateFilterValues(filterVar, ['true']);
  } else {
    dataStore.value.updateFilterValues(filterVar, []);
  }
};
</script>

<style scoped>
.filter-controls {
  padding: 0.7rem;
}

.filter-section {
  margin-bottom: 1rem;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: #f9f9f9;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, font-weight 0.3s;
  font-size: 0.9em;
  margin-bottom: 0.5rem;
}

.filter-header.active {
  background-color: #f0f0f0;
  font-weight: bold;
}

.filter-content {
  display: block;
}

.filter-content.expanded {
  display: block;
}

.checkbox-container {
  display: block;
}

.form-check {
  margin-bottom: 0.5rem;
}

.form-check-label {
  display: flex;
  align-items: center;
}

select[multiple] {
  width: 100%;
  min-height: 100px;
}

.date-range {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.date-range .form-control {
  width: 100%;
}

.form-control[type="date"] {
  width: 100%;
}

.toggle-icon {
  font-size: 0.8em;
  transition: transform 0.3s;
}

.filter-content {
  display: block;
}

.filter-content.expanded {
  display: block;
}

/* Add more styles as needed */

.form-check-input {
  margin-right: 0.5rem;  /* Adds spacing between checkbox and label text */
}

.numeric-range {
  padding: 2rem 0.5rem 0 0.5rem;  /* Increased top padding */
}

.range-values {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  font-size: 0.9em;
  color: #666;
}

/* Custom styles for vue-slider */
:deep(.vue-slider) {
  padding: 1rem 0;
}

:deep(.vue-slider-rail) {
  background-color: #e0e0e0;
}

:deep(.vue-slider-process) {
  background-color: #007bff;
}

:deep(.vue-slider-dot-handle) {
  border-color: #007bff;
}

:deep(.vue-slider-dot-handle-focus) {
  box-shadow: 0 0 0 5px rgba(0, 123, 255, 0.2);
}

.range-sliders {
  position: relative;
  padding: 0.5rem 0;
}

.range-sliders .form-range {
  width: 100%;
  margin-bottom: 0.5rem;
}

.column-selector-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.column-selector-btn:hover {
  background-color: #f0f0f0;
}

.column-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.column-modal {
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  width: 90%;
  max-width: 600px;
  max-height: none;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
}

.search-box {
  margin-bottom: 1rem;
}

.column-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: none;
}

.column-item {
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
}

.date-range-controls {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}

.date-inputs {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.btn-outline-primary.btn-sm {
  padding: 0.15rem 0.3rem;
  font-size: 0.75rem;
  min-width: auto;
  width: auto;
}

.multichoice-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #eee;
}

.multichoice-buttons .btn {
  padding: 0.15rem 0.5rem;
  font-size: 0.75rem;
}

/* Add this to ensure consistent spacing for both checkbox and select filters */
.filter-content .multichoice-buttons {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #eee;
}

.no-range-data {
  text-align: center;
  color: #666;
  padding: 1rem;
  font-style: italic;
}

/* Add new styles */
.population-filter {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
}

.filter-label {
  margin-bottom: 0.5rem;
  font-size: 0.9em;
  color: #666;
}

.eligibility-options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.radio-label input[type="radio"] {
  margin: 0;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-check-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.form-check-input {
  margin-right: 0.5rem;
}

/* Additional styles if necessary... */

.simple-checkbox {
  padding: 0.5rem 0;
}

.simple-checkbox .form-check-label {
  cursor: pointer;
}

</style>
