<template>
  <div class="chart-container">
    <div v-if="dataStore.isLoading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
    
    <div class="chart-wrapper" ref="chartWrapper">
      <canvas ref="chartCanvas"></canvas>
    </div>
    <div class="chart-helper-text">
      Hover over or click on any point to see detailed information
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch, computed, onUnmounted } from 'vue';
import { useDataStore } from '../stores/dataStore';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default defineComponent({
  name: 'TimeSeriesChartComponent',
  setup() {
    const dataStore = useDataStore();
    const chartCanvas = ref(null);
    const chartWrapper = ref(null);
    let chart = null;
    let resizeObserver = null;

    const timeSeriesConfig = computed(() => {
    //  return dataStore.config.diseaseParameters[dataStore.config.doid]?.timeSeriesConfig;
      return dataStore.getFrontendConfig?.timeSeriesConfig;
    });

    const getLineStyles = (index, isSelected, isHovered = false) => {
      const baseTransparency = isSelected ? 0.3 : (dataStore.selectedRow ? 0.1 : 0.3);
      const hoverTransparency = 0.9;
      
      return {
        borderColor: isSelected 
          ? `rgba(255, 99, 132, ${isHovered ? 1 : 0.9})`
          : `hsla(${index * 137.5 % 360}, 70%, 50%, ${isHovered ? hoverTransparency : baseTransparency})`,
        borderWidth: isSelected || isHovered ? 4 : 2,
        pointRadius: isSelected || isHovered ? 6 : 4,
        pointBackgroundColor: isSelected 
          ? `rgba(255, 99, 132, ${isHovered ? 1 : 0.9})`
          : `hsla(${index * 137.5 % 360}, 70%, 50%, ${isHovered ? hoverTransparency : baseTransparency})`,
        pointHoverRadius: 8,
        pointHoverBorderWidth: 3,
        tension: 0,
      };
    };

    const convertTimeToWeeks = (timeStr) => {
      const value = parseFloat(timeStr);
      const unit = timeStr.replace(/[\d.]/g, '').toLowerCase();
      
      switch (unit) {
        case 'w': return value;           // already in weeks
        case 'm': return value * 4.345;   // months to weeks (approximate)
        case 'y': return value * 52.143;  // years to weeks
        case 'd': return value / 7;       // days to weeks
        default: return value;            // if no unit specified, assume weeks
      }
    };

    const chartData = computed(() => {
      if (!timeSeriesConfig.value) return { datasets: [] };
      
      const data = dataStore.filteredData;
      const selectedRow = dataStore.selectedRow;
      const datasets = [];
      let validDataIndex = 0;

      data.forEach((item, index) => {
        const endpoint = item.endpoints?.[timeSeriesConfig.value.variable];
        if (!endpoint) return;

        const times = endpoint.meta?.times || [];
        const drugValues = endpoint.drug?.values || [];
        const placeboValues = endpoint.placebo?.values || [];
        const drugBaseline = endpoint.drug?.baseline_value;
        const placeboBaseline = endpoint.placebo?.baseline_value;

        // Only include records that have both drug and placebo data
        if (drugValues.length && placeboValues.length) {
          const isSelected = selectedRow && item === selectedRow;
          
          // Calculate drug-placebo difference with percentage conversion if needed
          const differenceData = [
            { x: 0, y: 0 },  // Add zero point
            ...times.map((time, i) => {
              let diffValue = null;
              if (drugValues[i] != null && placeboValues[i] != null) {
                // Convert to percentage if needed (matching dataStore logic)
                if (!endpoint.meta.value_unit?.includes('%') && 
                  endpoint.meta.value_type === "additive_change" &&
                  drugBaseline != null && placeboBaseline != null) {
                  diffValue = (drugValues[i]/drugBaseline - placeboValues[i]/placeboBaseline) * 100;
                }
                else if (endpoint.meta.value_type == "%") {
                  diffValue = drugValues[i] - placeboValues[i];
                }
              }

              return {
                x: convertTimeToWeeks(time),
                y: diffValue
              };
            })
          ];

          datasets.push({
            label: `${item.drug_name} (Drug - Placebo)`,
            data: differenceData,
            ...getLineStyles(validDataIndex, isSelected),
            borderDash: [],
            originalIndex: index,
            isSelected,
          });
          validDataIndex++;
        }
      });

      // Sort datasets to put selected items last (on top)
      datasets.sort((a, b) => {
        if (a.isSelected === b.isSelected) return 0;
        return a.isSelected ? 1 : -1;
      });

      return { datasets };
    });

    const handlePointClick = (event) => {
      if (!chart) return;
      
      const points = chart.getElementsAtEventForMode(
        event,
        'nearest',
        { intersect: true },
        false
      );
      
      if (points.length > 0) {
        const datasetIndex = points[0].datasetIndex;
        const originalIndex = chart.data.datasets[datasetIndex].originalIndex;
        const clickedItem = dataStore.filteredData[originalIndex];
        dataStore.setSelectedRow(clickedItem);
      }
    };

    const createChart = () => {
      if (!chartCanvas.value || !chartWrapper.value || !timeSeriesConfig.value) return;

      const ctx = chartCanvas.value.getContext('2d');
      if (!ctx) return;

      const wrapperWidth = chartWrapper.value.clientWidth;
      const wrapperHeight = chartWrapper.value.clientHeight;
      
      chartCanvas.value.width = wrapperWidth;
      chartCanvas.value.height = wrapperHeight;

      chart = new Chart(ctx, {
        type: 'line',
        data: chartData.value,
        options: {
          responsive: false,
          animation: false,  // Disable all animations
          interaction: {
            mode: 'nearest',
            intersect: true,  // Changed to true for more precise clicking
          },
          plugins: {
            title: {
              display: true,
              text: timeSeriesConfig.value.title,
              font: {
                size: 20,
                weight: 'bold'
              },
              padding: {
                top: 10,
                bottom: 20
              }
            },
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                title: (context) => {
                  const value = context[0].parsed.x;
                  return `Week ${value}`;
                }
              },
              mode: 'nearest',
              intersect: true,
              hover: {
                mode: 'nearest',
                intersect: false
              }
            }
          },
          scales: {
            x: {
              type: 'linear',
              title: {
                display: true,
                text: 'Weeks',
                font: { size: 16 }
              },
              min: 0,
              max: timeSeriesConfig.value?.timeRange 
                ? timeSeriesConfig.value.timeRange.max / 7  // Convert days to weeks
                : 110  // Default max if no timeRange specified
            },
            y: {
              title: {
                display: true,
                text: timeSeriesConfig.value.yAxisLabel,
                font: { size: 16 }
              }
            }
          },
          onClick: handlePointClick,
          elements: {
            point: {
              hitRadius: 10,  // Increases the clickable area around points
            }
          },
          onHover: (event, elements) => {
            if (!chart) return;
            
            // Reset all datasets to their original styles
            chart.data.datasets.forEach(dataset => {
              const isSelected = dataset.isSelected;
              const index = dataset.originalIndex;
              Object.assign(dataset, getLineStyles(index, isSelected));
            });

            // If hovering over a point, highlight all points in that dataset
            if (elements.length > 0) {
              const datasetIndex = elements[0].datasetIndex;
              const dataset = chart.data.datasets[datasetIndex];
              
              // Apply highlight styles to all points in the dataset
              Object.assign(dataset, getLineStyles(dataset.originalIndex, dataset.isSelected, true));
            }
            
            chart.update('none');
          },
        }
      });
    };

    const updateChart = () => {
      if (!chartCanvas.value || !chartWrapper.value) return;

      if (chart) {
        window.requestAnimationFrame(() => {
          chart.destroy();
          createChart();
        });
      } else {
        createChart();
      }
    };

    onMounted(() => {
      setTimeout(() => {
        createChart();
        
        resizeObserver = new ResizeObserver(() => {
          window.requestAnimationFrame(updateChart);
        });
        
        if (chartWrapper.value) {
          resizeObserver.observe(chartWrapper.value);
        }
      }, 0);
    });

    onUnmounted(() => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
      if (chart) {
        chart.destroy();
      }
    });

    watch(() => dataStore.filteredData, updateChart, { deep: true });
    watch(() => dataStore.selectedRow, updateChart);

    return {
      chartCanvas,
      chartWrapper,
      dataStore,
    };
  },
});
</script>

<style scoped>
.chart-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}

.chart-wrapper {
  width: 100%;
  height: 70vh;
  margin-top: 2rem;
  cursor: pointer;
}

.chart-helper-text {
  text-align: center;
  color: #666;
  margin-top: 1rem;
  font-style: italic;
}
</style> 