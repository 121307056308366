<template>
  <div class="details-component">
    <div>
      <h2>{{ selectedRow.title }}</h2>

      <div class="meta-info">
        <span class="date">{{ formatDate(selectedRow.date_time) }}</span>
        <span class="type">{{ selectedRow.type }}</span>
      </div>

      <div class="info-item" v-if="selectedRow.summary">
        <label>Summary</label>
        <ul class="key-points">
          <li v-for="(point, index) in selectedRow.summary.split('\n')" 
             :key="index">
            {{ point }}
          </li>
        </ul>
      </div>

      <div class="html-content" v-if="showHtml" v-html="selectedRow.html"></div>

      <div class="details-grid">
        <div class="info-item" v-if="selectedRow.drug_names?.length">
          <label>Drugs</label>
          <span>
            <span v-for="drug in selectedRow.drug_names" 
                  :key="drug" 
                  class="tag-item clickable"
                  :class="{ active: filterValues.drugs?.includes(drug) }"
                  @click="addToFilter('drugs', drug)">
              {{ drug }}
            </span>
          </span>
        </div>

        <div class="info-item" v-if="selectedRow.organization_names?.length">
          <label>Organizations</label>
          <span>
            <span v-for="org in selectedRow.organization_names" 
                  :key="org" 
                  class="tag-item clickable"
                  :class="{ active: filterValues.organizations?.includes(org) }"
                  @click="addToFilter('organizations', org)">
              {{ org }}
            </span>
          </span>
        </div>

        <div class="info-item" v-if="selectedRow.condition_names?.length">
          <label>Conditions</label>
          <span>{{ selectedRow.condition_names.join(', ') }}</span>
        </div>
      </div>

      <a href="#" 
         @click.prevent="toggleHtml" 
         class="source-link">
        {{ showHtml ? 'Hide Full Article' : 'View Full Article' }}
      </a>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { ref } from 'vue'
import { useNewsStore } from '../stores/newsStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'NewsDetailsComponent',
  
  props: {
    selectedRow: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const showHtml = ref(false)
    const newsStore = useNewsStore()
    const { filterValues } = storeToRefs(newsStore)

    const toggleHtml = () => {
      showHtml.value = !showHtml.value
    }

    const closeDetails = () => {
      emit('close')
    }

    const formatDate = (dateString) => {
      return format(new Date(dateString), 'MMM d, yyyy')
    }

    const addToFilter = (filterName, value) => {
      const currentValues = filterValues.value[filterName] || []
      
      if (currentValues.includes(value)) {
        newsStore.updateFilterValues(filterName, currentValues.filter(v => v !== value))
      } else {
        newsStore.updateFilterValues(filterName, [...currentValues, value])
      }
    }

    return {
      closeDetails,
      formatDate,
      showHtml,
      toggleHtml,
      filterValues,
      addToFilter
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.meta-info {
  margin: 10px 0 20px;
  color: #666;
}

.meta-info > span {
  margin-right: 20px;
}

.details-grid {
  display: grid;
  gap: 20px;
  margin: 20px 0;
}

.info-item {
  display: grid;
  gap: 5px;
}

.info-item label {
  color: #666;
  font-size: 0.9em;
}

.source-link {
  display: inline-block;
  margin-top: 20px;
  color: #4CB3D8;
  text-decoration: none;
}

.source-link:hover {
  text-decoration: underline;
}

.summary {
  margin: 20px 0;
}

.key-points {
  list-style: none;
  padding: 0;
  margin: 0;
}

.key-points li {
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 0.5em;
}

.key-points li:before {
  content: "•";
  position: absolute;
  left: 0.5em;
  color: #4CB3D8;
}

.html-content {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.html-content :deep(img) {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem auto;
}

.tag-item {
  background: #f8f9fa;
  padding: 6px 12px;
  border-radius: 16px;
  margin: 4px 8px 4px 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  opacity: 0.85;
}

.tag-item:hover {
  background-color: #e3f2fd;
  opacity: 1;
}

.tag-item.active {
  background-color: #e3f2fd;
  opacity: 1;
  font-weight: 500;
}

.clickable {
  cursor: pointer;
}
</style> 