import { createRouter, createWebHistory } from 'vue-router'
import DiseaseView from './components/DiseaseView.vue'
import NewsView from './components/NewsView.vue'
import AboutView from './components/AboutView.vue'
import LoginView from './views/LoginView.vue'
import { useAuthStore } from './stores/authStore'

const routes = [
  {
    path: '/',
    redirect: '/funds'
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
  },
  {
    path: '/funds',
    name: 'FundsView',
    component: NewsView,
    props: () => ({ 
      viewType: 'funds',
      disease: null,
    })
  },
  {
    path: '/investors',
    name: 'InvestorsView',
    component: NewsView,
    props: () => ({ 
      disease: null,
      viewType: 'investors'
    })
  },
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView,
  },
  {
    path: '/:disease',
    redirect: to => `/${to.params.disease}/results`
  },
  {
    path: '/:disease/results',
    name: 'DiseaseView',
    component: DiseaseView,
    props: true,
  },
  {
    path: '/:disease/catalysts',
    name: 'CatalystsView',
    component: NewsView,
    props: route => ({ 
      disease: route.params.disease,
      viewType: 'catalyst'
    })
  },
  {
    path: '/:disease/deals',
    name: 'DealsView',
    component: NewsView,
    props: route => ({ 
      disease: route.params.disease,
      viewType: 'deals'
    })
  },
  {
    path: '/:disease/events',
    name: 'NewsView',
    component: NewsView,
    props: route => ({ 
      disease: route.params.disease,
      viewType: 'news'
    })
  },
  {
    path: '/:disease/assets',
    name: 'AssetsView',
    component: NewsView,
    props: route => ({ 
      disease: route.params.disease,
      viewType: 'drugs'
    })
  },
  {
    path: '/:disease/trials',
    name: 'TrialsView',
    component: NewsView,
    props: route => ({ 
      disease: route.params.disease,
      viewType: 'trials'
    })
  },
  {
    path: '/logout',
    name: 'LogoutView',
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.logout()
      next('/login')
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Add navigation guard
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)

  if (authRequired && !authStore.isAuthenticated) {
    // Save the intended destination for redirect after login
    return next('/login')
  }

  next()
})

export default router