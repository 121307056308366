<template>
  <div class="fund-details">
    <h2>{{ selectedRow.name }}</h2>
    
    <div class="metadata">
      <div class="metadata-item">
        <span class="label">Type:</span>
        <span class="value">{{ selectedRow.investor_type }}</span>
      </div>
      <div class="metadata-item">
        <span class="label">Status:</span>
        <span class="value" :class="{ 'active': selectedRow.is_active }">
          {{ selectedRow.is_active ? 'Active' : 'Inactive' }}
        </span>
      </div>
      <div class="metadata-item">
        <span class="label">Biotech Focus:</span>
        <span class="value">
          {{ selectedRow.is_biotech_investor ? 'Yes' : 'No' }}
        </span>
      </div>
    </div>

    <div class="summary" v-if="selectedRow.summary">
      <h3>Summary</h3>
      <p>{{ selectedRow.summary }}</p>
    </div>

    <div class="bullet-points" v-if="selectedRow.bullet_points?.length">
      <h3>Key Points</h3>
      <ul>
        <li v-for="(point, index) in selectedRow.bullet_points" :key="index">
          {{ point }}
        </li>
      </ul>
    </div>

    <div class="validation">
      <small>Last validated: {{ formatDate(selectedRow.validated_at) }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FundDetailsComponent',
  
  props: {
    selectedRow: {
      type: Object,
      required: true
    }
  },

  methods: {
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      return new Date(dateString).toLocaleDateString();
    }
  }
}
</script>

<style scoped>
.fund-details {
  padding: 1.5rem;
}

h2 {
  margin-bottom: 1.5rem;
  color: #2c3e50;
}

h3 {
  margin: 1.5rem 0 1rem;
  color: #2c3e50;
  font-size: 1.1rem;
}

.metadata {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
}

.metadata-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.label {
  font-size: 0.9rem;
  color: #6c757d;
}

.value {
  font-weight: 500;
}

.value.active {
  color: #198754;
}

.summary {
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.bullet-points ul {
  list-style-type: none;
  padding: 0;
}

.bullet-points li {
  padding: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.bullet-points li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #0d6efd;
}

.validation {
  margin-top: 2rem;
  color: #6c757d;
}
</style> 