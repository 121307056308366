<template>
  <div class="details-component" v-if="selectedRow">
    <div>
      <h2>{{ selectedRow.brief_title }}</h2>

      <div class="meta-info">
        <span class="date">
          <i class="fas fa-calendar"></i>
          {{ formatDate(selectedRow.date) }}
        </span>
        <span class="types">
          <i class="fas fa-tags"></i>
          {{ formatTypes(selectedRow.types) }}
        </span>
        <span class="amount" v-if="selectedRow.total">
          <i class="fas fa-dollar-sign"></i>
          {{ formatAmount(selectedRow.total) }}
        </span>
      </div>

      <div class="details-grid">
        <div class="side-by-side">
          <div class="info-item" v-if="selectedRow.sellers?.length">
            <label>Sellers / Licensees</label>
            <span>
              <span v-for="seller in selectedRow.sellers" 
                    :key="seller" 
                    class="tag-item clickable"
                    :class="{ active: filterValues.sellers?.includes(seller) }"
                    @click="addToFilter('sellers', seller)">
                {{ seller }}
              </span>
            </span>
          </div>

          <div class="info-item" v-if="selectedRow.buyers?.length">
            <label>Buyers / Licensors</label>
            <span>
              <span v-for="buyer in selectedRow.buyers" 
                    :key="buyer" 
                    class="tag-item clickable"
                    :class="{ active: filterValues.buyers?.includes(buyer) }"
                    @click="addToFilter('buyers', buyer)">
                {{ buyer }}
              </span>
            </span>
          </div>
        </div>

        <div class="info-item" v-if="selectedRow.milestones">
          <label>Milestone Payments</label>
          <span>{{ formatAmount(selectedRow.milestones) }}</span>
        </div>
        
        <div class="info-item" v-if="selectedRow.summary?.length">
          <label>Key Points</label>
          <ul class="key-points">
            <li v-for="(point, index) in selectedRow.summary" :key="index">
              {{ point }}
            </li>
          </ul>
        </div>

        <div class="info-item" v-if="selectedRow.sources?.length">
          <label>Related News Articles</label>
          <NewsComponent :articles="selectedRow.sources" />
        </div>
      </div>

      <a v-if="selectedRow.source?.url" 
         :href="selectedRow.source.url" 
         target="_blank"
         class="source-link">
        <i class="fas fa-external-link-alt"></i>
        View Source
      </a>
    </div>
  </div>
  <div v-else class="details-component">
    <div class="no-selection">
      <p>Select a deal to view details</p>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { useNewsStore } from '../stores/newsStore'
import { storeToRefs } from 'pinia'
import NewsComponent from './NewsComponent.vue'
import '@/assets/styles/details-component.css'

export default {
  name: 'DealDetailsComponent',
  
  components: {
    NewsComponent
  },
  
  props: {
    selectedRow: {
      type: Object,
      required: true
    }
  },

  setup() {
    const newsStore = useNewsStore()
    const { filterValues } = storeToRefs(newsStore)
    return { filterValues }
  },

  methods: {
    formatDate(dateString) {
      if (!dateString) return 'N/A'
      return format(new Date(dateString), 'MMM d, yyyy')
    },

    formatAmount(amount) {
      if (!amount) return 'N/A'
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        notation: 'compact',
        maximumFractionDigits: 1
      }).format(amount)
    },

    capitalizeFirst(str) {
      if (!str) return ''
      return str.charAt(0).toUpperCase() + str.slice(1)
    },

    formatTypes(types) {
      if (!types || !types.length) return 'Unknown'
      return types.map(this.capitalizeFirst).join(', ')
    },

    addToFilter(filterName, value) {
      const newsStore = useNewsStore()
      const currentValues = this.filterValues[filterName] || []
      
      if (currentValues.includes(value)) {
        newsStore.updateFilterValues(filterName, currentValues.filter(v => v !== value))
      } else {
        newsStore.updateFilterValues(filterName, [...currentValues, value])
      }
    }
  }
}
</script>

<style scoped>
.no-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #666;
  font-style: italic;
}

.meta-info {
  margin: 10px 0 20px;
  color: #666;
}

.meta-info > span {
  margin-right: 20px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.source-link {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
  color: #4CB3D8;
  text-decoration: none;
}

.source-link:hover {
  text-decoration: underline;
}

.key-points {
  list-style: none;
  padding: 0;
  margin: 0;
}

.key-points li {
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 0.5em;
}

.key-points li:before {
  content: "•";
  position: absolute;
  left: 0.5em;
  color: #4CB3D8;
}
</style> 